import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import IconButton from "../common/iconButton";
import { Modal, Container, Col, Row, Button } from "react-bootstrap";

const Profile = ({
  user,
  editProfile,
  getCurrentUser,
  shouldUpdate,
  size = "large"
}) => {
  const [show, setShow] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.entities.auth);

  useEffect(() => {
    isLoggedIn && getCurrentUser();
  }, [isLoggedIn]);

  useEffect(() => {
    if (shouldUpdate) {
      getCurrentUser();
      setShow(false);
    }
  }, [shouldUpdate])

  const getInitialValues = () => {
    return {
      email: user ? user.email : "",
      password: "",
      passwordConfirmation: ""
    };
  };

  const handleSubmit = (data) => {
    editProfile(data);
  };

  return (
    <>
      <IconButton
        title="Profile"
        icon="profile"
        size={size}
        onClick={() => setShow(true)}
      />
      <Modal
        centered
        show={show}
        onHide={() => setShow(false)}
        className="text-center modal"
      >
        <Modal.Header className="p-0" closeButton closeVariant="white">
          <Modal.Title className="text-white text-large"> {user && user.fullName ? `${user.fullName} profile` : "Profile"} </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 mt-4">
          <Formik
            initialValues={getInitialValues()}
            onSubmit={(values) => {
              handleSubmit(values);
            }}
          >
            {() => (
              <Form className="text-white text-left" autoComplete="off">
                <div className="form-group">
                  <Field name="email">
                    {({ field }) => (
                      <div className="labeled-input fluid">
                        <input className="input" type="email" placeholder=" " required {...field} />
                        <label htmlFor="email"> Email </label>
                      </div>
                    )}
                  </Field>
                </div>

                <div className="form-group mt-4">
                  <Field name="password" autoComplete="off">
                    {({ field }) => (
                      <div className="labeled-input fluid">
                        <input className="input" type="password" placeholder=" " {...field} />
                        <label htmlFor="password"> New password </label>
                      </div>
                    )}
                  </Field>
                </div>

                <div className="form-group mt-4">
                  <Field name="passwordConfirmation" autoComplete="off">
                    {({ field }) => (
                      <div className="labeled-input fluid">
                        <input className="input" type="password" placeholder=" " {...field} />
                        <label htmlFor="passwordConfirmation"> Confirm new password </label>
                      </div>
                    )}
                  </Field>
                </div>

                <Container fluid className="p-0 mt-5">
                  <Row>
                    <Col className="text-end" xs={{ span: 8, offset: 4 }}>
                      <Button type="submit" className="button w-100">
                        Save
                      </Button>
                    </Col>
                  </Row>
                </Container>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Profile;
