import Task from "../task";
import SortBy from "../sortBy";
import { Search } from "../search";
import { Row, Col } from "react-bootstrap";
import { useEffect, useState, useCallback } from "react";
import ListItem from "../listItem/listItem";
import { TASK } from "../../utils/constants";
import { useParams } from "react-router-dom";
import { useSort } from "../../hooks/useSort";
import { useSearch } from "../../hooks/useSearch";
import { useSelector } from "react-redux";
import { TaskState } from "../taskChartCommon/taskState";
import { toast } from "react-toastify";

const TasksList = ({
  isLoading,
  tasks,
  getTasks,
  appendTask,
  patchTask,
  editTask,
  deleteTask,
  shouldUpdate,
  getDashboardData,
  setDashboardFilter,
  isOptionsVisible,
  indexedFields,
  getTaskDetails,
}) => {
  const { subjectId, projectId, ...extraParams} = useParams();
  const [items, setItems] = useState(tasks);
  const { sortBy, sortData } = useSort(indexedFields);
  const { search, searchValue } = useSearch(indexedFields);
  const { connection, isConnected } = useSelector((state) => state.entities.socket);
  const [activeTaskId, setActiveTaskId] = useState(extraParams.taskId);

  const handleMessage = useCallback(async (data) => {
    const { type } = data;

    switch(type) {
      case "TASK_CREATE":
        appendTask(data.task);
        getDashboardData({ projectId, subjectId });
        break;
      case "TASK_START":
      case "TASK_RESUME":
        patchTask({ objectId: data.taskId, state: TaskState.RUNNING });
        break;
      case "TASK_PAUSE":
        patchTask({ objectId: data.taskId, state: TaskState.PAUSED });
        break;
      case "TASK_COMPLETE":
        getTaskDetails(data.taskId);
        break;
      default:
        return;
    }
  }, [
    projectId,
    subjectId,
    appendTask,
    patchTask,
    getDashboardData,
    getTaskDetails
  ]);

  useEffect(() => {
    if (extraParams.taskId !== undefined) {
      getTaskDetails(extraParams.taskId);
    }

    setActiveTaskId(extraParams.taskId);
  }, [
    extraParams.taskId,
    getTaskDetails,
  ]);

  useEffect(() => {
    if (isConnected) {
      connection.on("message", handleMessage);
    }

    return () => {
      if (connection) {
        connection.off("message", handleMessage);
      }
    }
  }, [isConnected, connection, handleMessage]);

  useEffect(() => {
    getTasks(subjectId);
    setDashboardFilter({ selectedProjectId: projectId, selectedSubjectId: subjectId, selectedTaskId: activeTaskId });
  }, [shouldUpdate, getTasks, projectId, subjectId, setDashboardFilter, activeTaskId]);

  useEffect(() => {
    setItems(sortBy(search(tasks, searchValue), sortData));
  }, [sortBy, search, searchValue, sortData, tasks]);

  return (
    <div className="text-white d-flex flex-column gap-3">
      {!isLoading && isOptionsVisible &&
        <Row className="filters" style={{ rowGap: "16px" }}>
          <Col sm={4}>
            <SortBy
              keys={indexedFields}
              sortData={sortData}
              onSort={(sortData) => setItems(sortBy(items, sortData))}
            />
          </Col>

          <Col sm={{ span: 6, offset: 2 }}>
            <Search
              searchValue={searchValue}
              onSearch={(value) => setItems(sortBy(search(tasks, value), sortData))}
            />
          </Col>
        </Row>
      }

      <>
        {items.map((task) => (
          <ListItem
            item={task}
            title={task.title}
            description={task.description}
            source={TASK}
            key={task.objectId}
            replaceURL={true}
            active={task.objectId === activeTaskId}
            contentTemplateComponent={
              <Task
                data={task}
                handleDelete={(taskId) => {
                  if (taskId === activeTaskId) {
                    toast.error("Active task cannot be deleted.", { position: toast.POSITION.BOTTOM_LEFT });
                    return;
                  }

                  deleteTask(taskId);
                }}
                handleEdit={editTask}
              />
            }
          />
        ))}
      </>

      {(!isLoading && !searchValue && (!tasks || tasks.length === 0)) && <p className="items-container-empty"> No tasks. </p> }
      {searchValue && !items.length && <p className="items-container-empty"> No tasks found. </p>}
    </div>
  );
};

export default TasksList;
