import React, { useState } from "react";
import { Carousel } from "react-bootstrap";

const ControlledCarousel = ({ 
  items = [],
  className = "",
  onSelect = null
}) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <div className={className || ""}>
      <Carousel
        activeIndex={activeIndex}
        interval={null}
        controls={false}
        indicators={false}
        touch={true}
      >
        {items.map((item, index) => (
          <Carousel.Item key={index}>
            {item?.component}
          </Carousel.Item>
        ))}
      </Carousel>

      <div className="carousel-controls">
        {items.map((item, index) => (
          <button
            key={index}
            title={item.title}
            className={`carousel-controls-item ${activeIndex === index ? "active" : ""}`}
            onClick={() => { 
              setActiveIndex(index);
              onSelect && onSelect(item);
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default ControlledCarousel;