import SpectrumChart from './spectrumChart'

const SpectrumCharts = (
  {
    className = "",
    printLoading = () => {},
    data = {},
  }) => {

  return <>
    {printLoading()}
    <SpectrumChart
      className={className}
      title="Attention Detail"
      data={data.analytics.attention_grade_tuples}
    />
    <SpectrumChart
      className={className}
      title="Mental workload Detail"
      data={data.analytics.mental_workload_grade_tuples}
    />
    <SpectrumChart
      className={className}
      title="Stress Detail"
      data={data.analytics.stress_grade_tuples}
    />
    <SpectrumChart
      className={className}
      title="Relaxation Detail"
      data={data.analytics.relaxation_grade_tuples}
    />
    <SpectrumChart
      className={className}
      title="Vigilance Detail"
      data={data.analytics.vigilance_grade_tuples}
    />
  </>;
}

export default SpectrumCharts;
