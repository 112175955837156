import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import initialState from "../initialState";
import {
  SUBJECTS_URL as url,
  CREATE_SUBJECT_URL as createUrl,
} from "../constants";
import { toast } from "react-toastify";

const slice = createSlice({
  name: "subjects",
  initialState: initialState.subjects,
  reducers: {
    subjectsRequested: (subjects) => {
      subjects.loading = true;
    },

    subjectsReceived: (subjects, action) => {
      subjects.loading = false;
      subjects.list = action.payload.result;
    },

    subjectsFailed: (subjects) => {
      subjects.loading = false;
    },

    subjectCreateRequested: (subjects) => {},

    subjectCreated: (subjects, action) => {
      subjects.list = [...subjects.list, action.payload.result];

      toast.info("Subject created successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    },

    subjectEditRequested: (subjects) => {},

    subjectEdited: (subjects, action) => {
      const updatedSubjectIndex = subjects.list.findIndex((item) => item.objectId === action.payload.result.objectId);

      if (updatedSubjectIndex !== -1) {
        subjects.list[updatedSubjectIndex] = action.payload.result;        
      }
    },

    subjectEditFailed: (subjects, action) => {
      toast.info("Subject updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    },

    subjectDeleteRequested: (subjects) => {},

    subjectDeleted: (subjects, action) => {
      subjects.list = subjects.list.filter((project) => project.objectId !== action.payload.result.objectId);
    },

    subjectDeleteFailed: (subjects, action) => {
      toast.info("Subject deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    },

    subjectCreateFailed: (subjects) => {},

    subjectDetailsRequested: (subjects) => {
      subjects.loading = true;
    },

    subjectDetailsReceived: (subjects, action) => {
      subjects.currentSubject = action.payload.result;
    },

    subjectDetailsFailed: (subjects) => {
      subjects.loading = false;
    },
  },
});

const {
  subjectsRequested,
  subjectsReceived,
  subjectsFailed,

  subjectCreateRequested,
  subjectCreated,
  subjectCreateFailed,

  subjectEditRequested,
  subjectEdited,
  subjectEditFailed,

  subjectDeleteRequested,
  subjectDeleted,
  subjectDeleteFailed,

  subjectDetailsRequested,
  subjectDetailsReceived,
  subjectDetailsFailed,
} = slice.actions;
export default slice.reducer;

export const getSubjects = (projectId) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: `${url}/${projectId}`,
      onStart: subjectsRequested.type,
      onSuccess: subjectsReceived.type,
      onError: subjectsFailed.type,
    })
  );
};

export const createSubject = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: createUrl,
      method: "POST",
      data,
      onStart: subjectCreateRequested.type,
      onSuccess: subjectCreated.type,
      onError: subjectCreateFailed.type,
    })
  );
};

export const editSubject = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: `${createUrl}/${data.subjectId}`,
      method: "PUT",
      data,
      onStart: subjectEditRequested.type,
      onSuccess: subjectEdited.type,
      onError: subjectEditFailed.type,
    })
  );
};

export const deleteSubject = (subjectId) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: `${createUrl}/${subjectId}`,
      method: "DELETE",
      onStart: subjectDeleteRequested.type,
      onSuccess: subjectDeleted.type,
      onError: subjectDeleteFailed.type,
    })
  );
};

export const getSubjectDetails = (subjectId) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: `${createUrl}/${subjectId}`,
      onStart: subjectDetailsRequested.type,
      onSuccess: subjectDetailsReceived.type,
      onError: subjectDetailsFailed.type,
    })
  );
};
