import "./taskCharts.css"
import Breakpoint from "../breakpoint";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import InsightsChart from "./charts/insightsChart";
import {
  TASK_CHART_INSIGHTS,
  TASK_CHART_PREPROCESSED_EEG,
} from "../../store/constants";
import Carousel from "../carousel";
import { useParams } from "react-router-dom";
import ChannelsChart from "./charts/channelsChart";
import TaskChartNavigator from "../taskChartCommon/taskChartNavigator";
import { PreProcessedSignalsChart } from "./charts/preProcessedSignalsChart";
import {IGNORED_CHANNELS} from '../../utils/constants'

const TaskCharts = ({
  taskData,
  sendAlert,
  getTaskDetails,
  setActiveChart,
  setActiveChartPreset,
}) => {
  const { taskId } = useParams();
  const { activeChart, loading } = useSelector((state) => state.entities.tasks);
  const { connection: socket } = useSelector((state) => state.entities.socket);

  const getChartComponent = (chartId) => {
    const { preProcessedData, task } = taskData;

    const taskParams = {
      ...task,
      channels: task.channels.filter((channel) => !IGNORED_CHANNELS.includes(channel))
    }

    let props = { socket, task: taskParams, getTaskDetails };

    switch (chartId) {
      case TASK_CHART_PREPROCESSED_EEG:
        props = { ...props, initialData: preProcessedData };

        return (
          <ChannelsChart
            key="CHART-2"
            id="pre-procesed-eeg-chart"
            ignoreChannelMismatch={false}
            timeSeriesCollection={"filter"}
            timeSeriesSampleRate={task.sampleRatePreProcessedData}
            setActiveChartPreset={setActiveChartPreset}
            {...props}
          >
            {(chartProps) => <PreProcessedSignalsChart {...chartProps} />}
          </ChannelsChart>
        );

      case TASK_CHART_INSIGHTS:
        return <InsightsChart
          sendAlert={sendAlert}
          {...props}
        />;

      default:
        return null;
    };
  };

  useEffect(() => {
    return () => {
      setActiveChart({ id: TASK_CHART_PREPROCESSED_EEG });
      setActiveChartPreset(null);
    }
  }, [
    setActiveChart,
    setActiveChartPreset,
  ]);

  return (
    <>
      {taskData ? (
        <>
          <h2> {taskData.task.title} </h2>
          <p> {taskData.task.description ? taskData.task.description : "No description provided."} </p>
        </>
      ) : (
        <>
          Loading...
        </>
      )}

      <Breakpoint up="sm">
        <TaskChartNavigator />
      </Breakpoint>

      <Breakpoint down="xs">
        <Carousel
          className="px-4 pt-4"
          items={
            [{
              id: TASK_CHART_PREPROCESSED_EEG,
              title: "Preprocessed EEG",
            }, {
              id: TASK_CHART_INSIGHTS,
              title: "Insights and alerts",
            }]
          }
          onSelect={(chartData) => setActiveChart(chartData)}
        />
      </Breakpoint>

      <div className="chart">
        {(loading || !taskData || taskData.task.objectId !== taskId) ? null : getChartComponent(activeChart.id)}
      </div>
    </>
  );
}

export default TaskCharts;
