import "./App.scss";
import Main from "./pages/main";
import { Provider } from "react-redux";
import * as Sentry from "@sentry/react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import configureStore from "./store/configureStore";
import Initializer from "./components/common/initializer";

const store = configureStore();

function App() {
  return (
    <Provider store={store}>
      <Initializer />
      <div className="main-container">
        <ToastContainer />
        <Main />
      </div>
    </Provider>
  );
}

export default Sentry.withProfiler(App);
