import React from "react";
import { Link } from "react-router-dom";

const ItemsContainer = ({ title, children, href, className="", actionButtons = [] }) => {
  return (
    <div className={`items-container ${className}`}>
      <div className="items-container-heading d-flex justify-content-space-between">
        {title && href && <Link to={href} className="text-link text-heading text-large"> {title} </Link>}
        {title && !href && <h3 className="text-heading text-alternative text-large"> {title} </h3>}
        <div className="d-flex gap-3 align-items-center"> {actionButtons.map((actionButton) => (actionButton))} </div>
      </div>

      <div> {children} </div>
    </div>
  );
};

export default ItemsContainer;