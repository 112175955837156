import React from "react";
import { Formik, Form, Field } from "formik";
import { CREATE, EDIT } from "../../utils/constants";
import { Modal, Button, Container, Col, Row } from "react-bootstrap";

const CreateProjectModal = ({
  show,
  handleClose,
  handleSubmit,
  handleDelete,
  project,
  mode = CREATE,
}) => {
  const initialValues = {
    name: project ? project.name : "",
    description: project ? project.description : "",
  };

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      className="text-center modal"
    >
      <Modal.Header className="p-0" closeButton closeVariant="white">
        <Modal.Title className="text-white text-large">
          {mode === CREATE && "Create project"}
          {mode === EDIT && "Edit project"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 mt-4">
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSubmit({
              ...values,
              ...(project ? { objectId: project.objectId } : {}),
            });
          }}
        >
          {() => (
            <Form className="text-white text-left">
              <div className="form-group">
                <Field name="name">
                  {({ field }) => (
                    <div className="labeled-input fluid">
                      <input className="input" type="text" placeholder=" " required {...field} />
                      <label htmlFor="name"> Name* </label>
                    </div>
                  )}
                </Field>
              </div>
              <div className="form-group mt-4">
                <label htmlFor="description"> Description </label>
                <Field
                  as="textarea"
                  name="description"
                  className="form-control"
                  rows="7"
                />
              </div>

              <Container fluid className="p-0 mt-5">
                <Row sm={{ cols: 2 }}>
                  <Col className="text-left">
                    {mode === EDIT && (
                      <Button
                        onClick={handleDelete}
                        className="button link"
                      >
                        Delete project
                      </Button>
                    )}
                  </Col>
                  <Col className="text-end">
                    <Button
                      type="submit"
                      className="button w-100"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateProjectModal;
