import "./assets/i18n";
import App from "./App";
import React from "react";
import * as Sentry from "@sentry/react";
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { BrowserTracing } from "@sentry/tracing";

if (process.env.REACT_APP_SENTRY_ENABLED === "true") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: parseFloat(process.env.REACT_APP_SENTRY_TRACES_SR),
    replaysSessionSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SR),
    replaysOnErrorSampleRate: parseFloat(process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SR),
    integrations: [
      new BrowserTracing(),
      new Sentry.Replay(),
    ],
  });
}

const container = document.getElementById("root");

createRoot(container).render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,  
);
