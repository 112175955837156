const enTranslations = {
  translation: {
    appName: "InnoBrain",
    submit: "submit",
    username: "username",
    password: "password",
    forgotPassword: "forgot password",
    home: "home",
    user: "user",
    removeProjectConfirmText:
      "Once you delete a project and all its subjects, you can't undo it!",
    removeSubjectConfirmText: "Once you delete a subject, you can't undo it!",
    removeTaskConfirmText: "Once you delete a task, you can't undo it!",
  },
};

export default enTranslations;
