import { Field } from "formik";

export const FormField = ({
    name,
    type,
    required,
    labelName,
    clientErrors,
    serverErrors,
    touched
}) => {
    return (
        <Field name={name}>
        {({ field }) => (
          <div className="labeled-input fluid">
            <input className="input" type={type} placeholder=" " required={required} {...field} />
            <label htmlFor={name}> {labelName}{required && "*"} </label>
            <div className="errors-list">
              {touched[name] && clientErrors[name] && <span className="error">{clientErrors[name]}</span>}
              {serverErrors[name]?.map((errorText, index) => <span key={`${name}-error-${index}`} className="error">{errorText}</span>)}
            </div>
          </div>
        )}
      </Field>
    )
}