import React from "react"
import PropTypes from "prop-types"
import { withRouter } from "react-router"

const LinkItem = (props) => {
  const {
    to,
    match,
    history,
    location,
    staticContext,
    onClick,
    ...rest
  } = props
  return (
    <div
      {...rest}
      onClick={(event) => {
        if (document.body.dataset.hasOwnProperty("rrUiModalOpen"))
          return;

        onClick && onClick(event);
        history.push(to);
      }}
    >
      {props.children}
    </div>
  )
}

LinkItem.propTypes = {
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default withRouter(LinkItem);
