import * as am5 from "@amcharts/amcharts5";
import {useRef, useLayoutEffect} from 'react';
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const SemiPieChartAM5 = ({title, data, categoryField, valueField, colors, score}) => {
  const chartRef = useRef(null);
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    const root = am5.Root.new(containerRef.current);
    const darkTheme = am5themes_Dark.new(root);
    const responsiveTheme = am5themes_Responsive.new(root);
    const animatedTheme = am5themes_Animated.new(root);

    root.setThemes([animatedTheme, darkTheme, responsiveTheme]);
    root._logo.dispose();

    const chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        startAngle: 160, endAngle: 380
      })
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: valueField,
        categoryField: categoryField,
        startAngle: 160,
        endAngle: 380,
        radius: am5.percent(70),
        innerRadius: am5.percent(85)
      })
    );

    const colorSet = am5.ColorSet.new(root, {
      colors: colors,
      passOptions: {
        lightness: -0.05,
        hue: 0
      }
    });

    series.set("colors", colorSet);

    const label = chart.seriesContainer.children.push(
      am5.Label.new(root, {
        textAlign: "center",
        centerY: am5.p100,
        centerX: am5.p50,
        text: `[fontSize:18px]total[/]:\n[bold fontSize:30px]${score}[/]`
      })
    );

    series.data.setAll(data);

    chartRef.current = chart;

    chart.appear(1000, 100);

    return () => {
      root.dispose();
    }
  }, [data]);

  return (
    <div>
      {title && <p className="text-larger font-weight-bold text-center"> {title} </p>}
      <div ref={containerRef} style={{minHeight: 250}}></div>
    </div>
  )
};

export default SemiPieChartAM5;
