import { useState } from "react";
import { EDIT } from "../../utils/constants";
import { withTranslation } from "react-i18next";
import IconButton from "../common/iconButton";
import CreateSubjectModal from "../createSubjectModal";
import ConfirmRemoveModal from "../confirmRemoveModal";

const EditSubject = ({ item: subject, editSubject, deleteSubject, t }) => {
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);

  const handleDeleteButtonClick = () => {
    setShow(false);
    setShowConfirm(true);
  };

  const handleSubmit = (values) => {
    setShow(false);
    editSubject({ ...values, subjectId: subject.objectId });
  };

  const handleDelete = (a) => {
    setShowConfirm(false);
    deleteSubject(subject.objectId);
  };

  return (
    <>
      <IconButton
        id={`edit-subject-${subject.objectId}`}
        onClick={() => setShow(true)}
        icon="pen"
        size="small"
      />
      <CreateSubjectModal
        show={show}
        subject={subject}
        mode={EDIT}
        handleDelete={handleDeleteButtonClick}
        handleClose={() => setShow(false)}
        handleSubmit={handleSubmit}
        extraIds={{ subjectId: subject.objectId }}
      />
      <ConfirmRemoveModal
        show={showConfirm}
        text={t("removeSubjectConfirmText")}
        handleClose={() => setShowConfirm(false)}
        handleDelete={handleDelete}
      />
    </>
  );
};

export default withTranslation()(EditSubject);
