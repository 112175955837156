import { connect } from "react-redux";
import EditProject from "./editProject";
import { deleteProject, editProject } from "../../store/slices/projects";

function mapDispatchToProps(dispatch) {
  return {
    editProject: (data) => dispatch(editProject(data)),
    deleteProject: (projectId) => dispatch(deleteProject(projectId)),
  };
}

export default connect(null, mapDispatchToProps)(EditProject);
