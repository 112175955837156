import LinkItem from "./linkItem";
import { useRouteMatch } from "react-router-dom";

const ListItem = ({
  item,
  source,
  title,
  description,
  actionButtons = [],
  contentTemplateComponent,
  active = false,
  onClick = () => {},
  replaceURL = false
}) => {
  const { url } = useRouteMatch();

  const getClickUrl = () => {    
    if (replaceURL) {
      const sourceIndex = url.lastIndexOf(`/${source}/`);

      if (sourceIndex !== -1) {
        const partialURL = url.substring(0, sourceIndex);
        return `${partialURL}/${source}/${item.objectId}/`;
      }
    }

    return `${url}${source}/${item.objectId}/`;
  };

  return (
    <div className={`item hover-source ${active ? 'active' : ''}`}>
      <LinkItem
        to={{ pathname: getClickUrl(), state: {} }}
        className="nav-link text-white d-flex flex-column gap-2"
        onClick={() => onClick(item.objectId)}
      >
        {contentTemplateComponent ? (
          contentTemplateComponent
        ) : (
          <>
            <h3 className="text-heading text-initial text-medium" style={{ fontWeight: "bold" }}>
              {title}
            </h3>
            <p className="mb-2">
              {description || "No description provided."}
            </p>
            <div className="hover-target d-flex gap-2 justify-content-end">
              {actionButtons.map((actionButton) => (actionButton))}
            </div>
          </>
        )}
      </LinkItem>
    </div>
  );
};

export default ListItem;
