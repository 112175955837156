/**
 * @param {({
 *    title?: string,
 *    onClick: () => void,
 *    size?: "small" | "medium" | "large",
 *    isDisabled?: boolean,
 *    icon: 
 *      "profile" | 
 *      "bars-horizontal" | 
 *      "logout" | 
 *      "arrow-left" | 
 *      "pen" | 
 *      "times" | 
 *      "sliders" | 
 *      "add" |
 *      "chevron-left" |
 *      "chevron-right"
 *    ...props
 *  }) => ReactElement
 * }
 */
const IconButton = ({ icon, onClick, className, size = "medium", isDisabled = false, ...props }) => {
  return (
    <button
      type="button"
      className={`button icon ${className}`}
      disabled={isDisabled}
      onClick={(event) => {
        event.stopPropagation();
        onClick && onClick(event);
      }}
      {...props}
    >
      <span className={`icon ${icon} ${size}`}></span>
    </button>
  );
};

export { IconButton };
export default IconButton;
