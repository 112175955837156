import React, { createRef } from "react";
import { Formik, Form, Field } from "formik";
import DropdownField from "../dropdownField";
import { ClipboardButton } from "./clipboardButton";
import { CREATE, EDIT } from "../../utils/constants";
import { Modal, Button, InputGroup, Container, Row, Col } from "react-bootstrap";

const CreateSubjectModal = ({
  show,
  handleClose,
  handleSubmit,
  handleDelete,
  subject,
  extraIds,
  mode = CREATE,
}) => {
  const usernameInputRef = createRef();
  const passwordInputRef = createRef();

  const initialValues = {
    name: subject ? subject.name : "",
    gender: subject ? subject.gender : "",
    age: subject ? subject.age : "",
    handedness: subject ? subject.handedness : "",
    email: subject ? subject.email : "",
    phone: subject ? subject.phone : "",
    description: subject ? subject.description : "",
  };

  return (
    <Modal
      centered
      show={show}
      onHide={handleClose}
      className="text-center modal"
    >
      <Modal.Header className="p-0" closeButton closeVariant="white">
        <Modal.Title className="text-white text-large">
          {mode === CREATE && "Create subject"}
          {mode === EDIT && "Edit subject"}
        </Modal.Title>{" "}
      </Modal.Header>
      <Modal.Body className="p-0 mt-4">
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            handleSubmit({ ...values, projectId: extraIds?.projectId });
          }}
        >
          {() => (
            <Form className="text-white text-left form flex flex-direction-column">
              <div className="form-group">
                <Field name="name">
                  {({ field }) => (
                    <div className="labeled-input fluid">
                      <input className="input" type="text" placeholder=" " required {...field} />
                      <label htmlFor="name"> Name* </label>
                    </div>
                  )}
                </Field>
              </div>

              <div className="form-group">
                <DropdownField
                  name="gender"
                  items={[
                    {
                      title: "Female",
                      value: "female"
                    },
                    {
                      title: "Male",
                      value: "male"
                    }
                  ]} />
              </div>

              <div className="form-group">
                <Field name="age">
                  {({ field }) => (
                    <div className="labeled-input fluid">
                      <input min={0} max={100} className="input" type="number" placeholder=" " required {...field} />
                      <label htmlFor="age"> Age* </label>
                    </div>
                  )}
                </Field>
              </div>

              <div className="form-group">
                <DropdownField
                  name="handedness"
                  items={[
                    {
                      title: "Left handed",
                      value: "left"
                    },
                    {
                      title: "Right handed",
                      value: "right"
                    }
                  ]} />
              </div>

              <div className="form-group">
                <Field name="email">
                  {({ field }) => (
                    <div className="labeled-input fluid">
                      <input className="input" placeholder=" " type="email" {...field} />
                      <label htmlFor="email"> Email </label>
                    </div>
                  )}
                </Field>
              </div>

              <div className="form-group">
                <Field name="phone">
                  {({ field }) => (
                    <div className="labeled-input fluid">
                      <input className="input" placeholder=" " type="text" {...field} />
                      <label htmlFor="phone"> Phone </label>
                    </div>
                  )}
                </Field>
              </div>

              <div className="form-group">
                <label htmlFor="description"> Description </label>
                <Field
                  rows="7"
                  as="textarea"
                  name="description"
                  className="form-control"
                  placeholder="Health conditions, specific disorder, drug usage…"
                />
              </div>

              {mode === EDIT && (
                <div className="form-group d-flex">
                  <div className={"d-flex flex-grow-1 gap-2"}>
                    <Row className="gy-3">
                      <Col>
                        <div className="labeled-input fluid d-flex">
                          <input disabled className="input" placeholder=" " type="text" value={subject.username} ref={usernameInputRef} />
                          <label htmlFor="username"> Username </label>
                          <ClipboardButton acquireContent={() => usernameInputRef.current.value} />
                        </div>
                      </Col>

                      <Col>
                        <div className="labeled-input fluid d-flex">
                          <input disabled className="input" placeholder=" " type="text" value={subject.password} ref={passwordInputRef} />
                          <label htmlFor="password"> Password </label>
                          <ClipboardButton acquireContent={() => passwordInputRef.current.value} />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>)}

              <Container fluid className="p-0 mt-3">
                <Row sm={{ cols: 2 }}>
                  <Col className="text-left">
                    {mode === EDIT && (
                      <Button
                        onClick={handleDelete}
                        className="button link"
                      >
                        Delete subject
                      </Button>
                    )}
                  </Col>
                  <Col className="text-end">
                    <Button
                      type="submit"
                      className="button w-100"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateSubjectModal;
