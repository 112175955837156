import * as Sentry from "@sentry/react";
import { configureStore } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducer from "./reducer";
import api from "./middleware/api";
import initialState from "./initialState";
import errorHandler from "./middleware/toast";
import socketMiddleware from "./middleware/socket";

const persistConfig = {
  key: "root",
  storage,
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

export default function configureReduxStore() {
  const store = configureStore({
    reducer: persistReducer(persistConfig, reducer),
    initialState,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({ serializableCheck: false }),
      socketMiddleware,
      errorHandler,
      api,
    ],
    enhancers: [sentryReduxEnhancer]
  });

  return store;
}
