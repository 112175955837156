import { useEffect } from "react";
import logout from "../store/logout";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import authService from "../services/authService";

const Logout = ({ logoutDispatcher }) => {
  const history = useHistory();

  useEffect(() => {
    authService.logout();
    logoutDispatcher();
  }, [logoutDispatcher, history]);

  return null;
};

function mapDispatchToProps(dispatch) {
  return {
    logoutDispatcher: () => dispatch(logout()),
  };
}

export default connect(null, mapDispatchToProps)(Logout);
