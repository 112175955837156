import io from "socket.io-client";
import { socketConnected, socketDisconnected, socketIntroduceSubjectAck, socketReconnected, socketSpeedTestDone, /*socketLatencyPush*/ } from "../actions/socket";
import {
  SOCKET_TRANSPORTS,
  SOCKET_AUTO_CONNECT,
  SOCKET_RECONNECTION,
  SOCKET_RECONNECTION_DELAY,
  SOCKET_RECONNECTION_DELAY_MAX,
} from "../constants";

const socketMiddleware = ({ dispatch }) => {
  const socket = io(process.env.REACT_APP_SOCKET_URL, {
    reconnection: SOCKET_RECONNECTION,
    reconnectionDelay: SOCKET_RECONNECTION_DELAY,
    reconnectionDelayMax: SOCKET_RECONNECTION_DELAY_MAX,
    reconnectionAttempts: 100,
    transports: SOCKET_TRANSPORTS,
    autoConnect: SOCKET_AUTO_CONNECT,
    retries: 10,
  });

  socket.on("connect", () => {
    dispatch({
      type: socketConnected.type,
      payload: { connection: socket }
    });
  });

  socket.on("message", async (packet) => {
    const { type } = packet;

    switch (type) {
      case "introduce_ack":
        dispatch({ type: socketIntroduceSubjectAck });
        break;
      case "speed_test_payload":
        const data = {
          type: "speed_test_client_ts",
          userId: packet.userId,
          serverTs: packet.serverTs,
          clientTs: Date.now(),
        };

        socket.send(data);
        break;
      case "speed_test_server_ack_ts":
        const clientAckTs = Date.now();
        const { clientTs, serverTs, serverAckTs } = packet;
        const latency = (serverAckTs - serverTs + clientAckTs - clientTs) * 0.5;
        dispatch({ type: socketSpeedTestDone, payload: { latency } });
        break;
      default:
        break;
    }
  });

  socket.on("disconnect", () => {
    dispatch({ type: socketDisconnected.type });
  });

  socket.on("reconnect", () => {
    dispatch({
      type: socketReconnected.type,
      payload: { isConnected: socket.connection.connected }
    });
  });

  return (next) => (action) => {
    next(action);
  }
}

export default socketMiddleware;
