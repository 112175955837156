const LoadingComponent = ({ className = "" }) => {
  return (
    <div className={`col-12 position-relative ${className}`}>
      <div
        className="spinner-border text-secondary position-absolute r-0 l-0 m-auto"
        role="status"
      >
        <span className="sr-only"></span>
      </div>
    </div>
  );
};

export default LoadingComponent;
