import initialState from "../initialState";
import { createSlice } from "@reduxjs/toolkit";
import { SOCKET_SPEED_TEST_RUNS } from "../constants";
import { getUserId } from "../../services/authService";
import { socketConnected, socketDisconnected, socketIntroduceSubject, socketIntroduceSubjectAck, socketReconnected, socketSpeedTest, socketSpeedTestDone } from "../actions/socket";

const slice = createSlice({
  name: "socket",
  initialState: initialState.socket,
  extraReducers: (builder) => {
    builder
      .addCase(socketConnected, (state, action) => {
        state.isConnected = true;
        state.connection = action.payload.connection;
      })
      .addCase(socketDisconnected, (state, _action) => {
        state.isConnected = false;
      })
      .addCase(socketReconnected, (state, action) => {
        state.isConnected = action.payload.isConnected;
      })
      .addCase(socketIntroduceSubject, (state, action) => {
        state.connection.emit("message", { type: "introduce", id: action.payload.id });
      })
      .addCase(socketIntroduceSubjectAck, (state, _action) => {
          state.isIntroduced = true;
      })
      .addCase(socketSpeedTest, (state, action) => {
        state.speedTestRuns = 0;
        state.speedTestLatencies = [];
        state.isSpeedTestRunning = true;

        if (state.connection !== null) {
          state.connection.emit("message", { type: "speed_test", id: action.payload.id });
        }
      })
      .addCase(socketSpeedTestDone, (state, action) => {
        state.speedTestLatencies.push(action.payload.latency);
        state.speedTestRuns++;

        if (state.speedTestRuns < SOCKET_SPEED_TEST_RUNS) {
          state.connection.emit("message", { type: "speed_test", id: getUserId() })
        } else {
          state.isSpeedTestDone = true;
          state.isSpeedTestRunning = false;
          state.connectionLatency = (state.speedTestLatencies.reduce((sum, latency) => sum + latency, 0) / SOCKET_SPEED_TEST_RUNS).toFixed(2);
        }
      });
  }
});

export const introduceSubject = (id) => (dispatch) => {
  dispatch({ type: socketIntroduceSubject.type, payload: { id } });
};

export const requestSpeedTest = (id) => (dispatch) => {
  dispatch({ type: socketSpeedTest.type, payload: { id } });
};

export default slice.reducer;