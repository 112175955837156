import React from "react";

const Icon = ({
  name,
  size = 20,
  extraClasses,
  onClick,
  extraProps,
  title,
  chartIcon = false,
}) => {
  const getSource = () => {
    return `/images/icons${chartIcon ? "/chart_icons/" : "/"}ic_${name}.svg`;
  };

  const getClasses = () =>
    `
    ${extraClasses && extraClasses}
    ${
      !extraClasses || (extraClasses && extraClasses.indexOf("m-") === -1)
        ? "m-2"
        : ""
    } `;

  const getStyle = () => {
    const style = {
      height: size,
      width: size,
      backgroundImage: `url(${getSource()})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "center",
      verticalAlign: "middle",
      display: "inline-block",
    };
    return style;
  };

  return (
    <div
      style={getStyle()}
      className={getClasses()}
      onClick={onClick}
      title={title}
      {...extraProps}
    ></div>
  );
};

Icon.defaultProps = {
  name: "",
  size: 20,
  extraClasses: "",
  onClick: () => {},
  extraProps: null,
};

export default Icon;
