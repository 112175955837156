import { useField } from "formik";
import { useEffect, useState } from "react";
import { Dropdown, ButtonGroup, Button } from "react-bootstrap";

/**
 * @param {({
 *   name: string,
 *   items: {
 *    title: string,
 *    value: string,
 *  }[]
 * })}
 */
const DropdownField = ({ items, ...props }) => {
  const [field, meta, helpers] = useField(props.name);
  const [selectedItem, setSelectedItem] = useState(items[0]);

  const handleSelect = (item) => {    
    setSelectedItem(item);
    helpers.setValue(item.value);
  };

  useEffect(() => {
    const item = items.find((item) => item.value === field.value) || items[0];
    handleSelect(item);
  }, []);

  return (
    <Dropdown as={ButtonGroup} className="d-grid custom-dropdown fluid" name={props.name}>
      <Dropdown.Toggle as={Button} className="text-left" style={{}}>
        <span> {selectedItem.title} </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="w-100 text-left" variant="dark">
        {items.map((item) => (
          <Dropdown.Item
            className={"w-100"}
            key={item.value}
            eventKey={item.value}
            active={selectedItem.value === item.value}
            onClick={() => handleSelect(item)}
          >
            {item.title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownField;