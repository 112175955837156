import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import faTranslations from "./fa.resources";
import enTranslations from "./en.resources";

const resources = {
  fa: faTranslations,
  en: enTranslations,
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
