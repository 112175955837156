import { Chart } from "./chart";
import { useEffect } from "react";
import "rc-slider/assets/index.css";
import { ChartFilter } from "./chartFilter";
import { ChartPaginator } from "./chartPaginator";
import { TIME_SERIES_PRESETS_ONLINE, TIME_SERIES_PRESETS_OFFLINE } from "../../../utils/constants";

export const PreProcessedSignalsChart = ({
  id,
  task,
  state,
  socket,
  addData,
  dispatch,
  legendData,
  getTaskDetails,
  containerHeight,
  processPageChange,
  handlePresetChange
}) => {
  useEffect(() => {
    if (!socket) {
      return;
    }

    const handleMessage = async (packet) => {
      const { data, taskId: packetTaskId, type: packetType } = packet;

      if (task.objectId !== packetTaskId) {
        console.warn(`Wrong task received data for type '${packetType}'. Expected '${task.objectId}', but received '${packetTaskId}'. this is a packet ${packet}`);
        return;
      }

      switch (packetType) {
        case "PRE_PROCESS_ADD":
          addData(data);
          break;

        case "TASK_START":
          dispatch({ type: "SET_TASK_RUNNING" });
          break;

        default:
          break;
      }
    };

    socket.on("message", handleMessage);
    return () => socket.off("message", handleMessage);
  }, [socket, addData, dispatch, task.objectId]);

  return (
    <div>
      {!state.isEmpty && (
        <>
          <ChartFilter
            key={`${id}-FILTER`}
            activeTab={state.activeTab}
            isOnline={state.isStreaming}
            handlePresetChange={handlePresetChange}
            onlinePresets={TIME_SERIES_PRESETS_ONLINE}
            offlinePresets={TIME_SERIES_PRESETS_OFFLINE}
          />
        </>
      )}

      <Chart
        id={id}
        key={`${id}-OVERLAY`}
        height={containerHeight}
        legendData={legendData}
        isLoading={state.isLoading}
        isEmpty={state.isEmpty}
        emptyStateMessage={"No pre-processed data is available for this task, you may have to wait for real-time data!"}
      />

      {!state.isStreaming && !state.isEmpty && (
        <ChartPaginator
          page={state.page}
          key={`${id}-PAGINATOR`}
          activeTab={state.activeTab}
          processPageChange={processPageChange}
        />
      )}
    </div>
  );
}
