import * as React from "react";
import Tooltip from "rc-tooltip";
import raf from "rc-util/lib/raf";
import "rc-tooltip/assets/bootstrap.css";

const TooltipHandle = (props) => {
    const { value, children, visible, tipFormatter = (value) => `${value}`, ...restProps } = props;
    const tooltipRef = React.useRef();
    const rafRef = React.useRef(null);

    function cancelKeepAlign() {
        raf.cancel(rafRef.current);
    }

    function keepAlign() {
        rafRef.current = raf(() => {
            tooltipRef.current?.forcePopupAlign();
        });
    }

    React.useEffect(() => {
        if (visible) keepAlign();
        else cancelKeepAlign();

        return cancelKeepAlign;
    }, [value, visible]);

    return (
        <Tooltip
            placement="top"
            overlay={tipFormatter(value)}
            overlayInnerStyle={{ minHeight: "auto" }}
            ref={tooltipRef}
            visible={visible}
            {...restProps}
        >
            {children}
        </Tooltip>
    );
};

export default TooltipHandle;