import React from "react";
import Profile from "../profile";
import { Navbar } from "react-bootstrap";
import IconButton from "../common/iconButton";
import { connect, useSelector } from "react-redux";
import { NavLink, useHistory } from "react-router-dom";

const Header = ({ className }) => {
  const history = useHistory();
  const { isLoggedIn } = useSelector((state) => state.entities.auth);

  return (
    <Navbar variant="dark" className={`Header header-nav ${className || ""}`}>
      <NavLink to="/">
        <div className="flex align-items-center">
          <img alt="" src="/images/innobrain-logo-white.svg" className="d-inline-block align-top brand-logo mobile" />
          <img alt="" src="/images/innobrain-logo-multicolor.svg" className="d-inline-block align-top brand-logo desktop" />
          <h2 className="brand-label"> InnoBrain </h2>
        </div>
      </NavLink>

      <Navbar.Collapse className="justify-content-end">
        {isLoggedIn && (
          <>
            <IconButton title="Logout" icon="logout" onClick={() => history.push("/account/logout")} />
            <Profile size="medium" />
            <IconButton title="Menu" icon="bars-horizontal" />
          </> 
        )}
      </Navbar.Collapse>
    </Navbar>
  );
};

export default connect(null, null)(Header);
