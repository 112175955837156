import TasksList from "./tasksList";
import { connect } from "react-redux";
import { getDashboardData, setDashboardFilter } from "../../store/slices/dashboard";
import { createTask, editTask, deleteTask, getTasks, appendTask, patchTask, getTaskDetails } from "../../store/slices/tasks";

function mapStateToProps(state) {
  const {
    list: tasks,
    shouldUpdate,
    loading: isLoading,
  } = state.entities.tasks;
  return {
    tasks,
    shouldUpdate,
    isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getTasks: (subjectId) => dispatch(getTasks(subjectId)),
    createTask: (data) => dispatch(createTask(data)),
    appendTask: (data) => dispatch(appendTask(data)),
    patchTask: (data) => dispatch(patchTask(data)),
    editTask: (data) => dispatch(editTask(data)),
    deleteTask: (taskId) => dispatch(deleteTask(taskId)),
    getDashboardData: (data) => dispatch(getDashboardData(data)),
    setDashboardFilter: (data) => dispatch(setDashboardFilter(data)),
    getTaskDetails: (taskId) => dispatch(getTaskDetails(taskId)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksList);
