import { toast } from "react-toastify";

function errorHandler(error) {
  if (error.response) {
    const code = error.response.status;
    const expectedError = code >= 400 && code < 500;
    if (!expectedError) {
      toast.error("An unexpected error occurred.", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }
  return Promise.reject(error);
}

export default errorHandler;
