import moment from "moment";
import {capitalize} from "lodash";
import {useEffect, useState} from "react";
import {Row, Col} from "react-bootstrap";
import {useSelector} from "react-redux";
import PieChartAM5 from "../charts/pieChartAM5";
import BarChartAM5 from "../charts/barChartAM5";
import AreaChartAM5 from "../charts/areaChartAM5";
import * as am5 from "@amcharts/amcharts5";
import GrainyBarChartAM5 from '../charts/grainyBarChartAM5'
import HeatMapChartAM5 from '../charts/heatMapChartAM5'
import DonutChartAM5 from '../charts/donutChartAM5'
import SpectrumCharts from './utils/spectrumCharts'
import GradeCharts from './utils/gradeCharts'
import DivergentCharts from './utils/divergentCharts'
import DurationChart from './utils/durationChart'
import {TASK_CHART_CHARTS} from '../../store/constants'
import {BLUE_COLOR, ORANGE_COLOR} from './utils/colors'

const DashboardCharts = ({className = "", taskPage = false}) => {
  const {data, loadingQueries} = useSelector((state) => state.entities.dashboard);
  const {activeChart} = useSelector((state) => state.entities.tasks);

  const [chartsData, setChartsData] = useState({
    minutesRecorded: [],
    subjectsPerGender: [],
    subjectsPerAgeGroup: [],
    tasksFrequencyPer90Days: [],
    tasksFrequencyPerDay: [],
    attentionSummary: [],
    stressSummary: [],
    vigilanceSummary: [],
    relaxationSummary: [],
    mentalWorkLoadSummaryScore: 0,
    attentionSummaryScore: 0,
    stressSummaryScore: 0,
    vigilanceSummaryScore: 0,
    relaxationSummaryScore: 0,
    mentalWorkLoadSummary: [],
    taskPeriod: [],
    workingHours: [],
    ageGroup: [],
    genderCount: [],
  });

  const processMinutesRecorded = () => {
    if (typeof data.charts.minutesRecorded === 'number') {
      return []
    }
    return data.charts.minutesRecorded.filter((item) => item.duration !== 0);
  }

  const processSubjectsPerGender = () => {
    return data.charts.subjectsPerGender.map((item) => ({
      name: capitalize(item.gender),
      count: item.count,
    }));
  }

  const processSubjectsPerAgeGroup = () => {
    return data.charts.subjectsPerAgeGroup.length === 0 ? [] : [
      "18 - 24",
      "25 - 34",
      "35 - 44",
      "45 - 54",
      "55+"
    ].map((group) => {
      const entry = data.charts.subjectsPerAgeGroup.find((groupData) => groupData.ageGroup === group);

      return {
        category: group,
        value: entry ? Math.round(entry.count / data.charts.subjectsPerAgeGroup.reduce((sum, groupData) => sum + groupData.count, 0) * 100) : 0
      };
    });
  }

  const processTasksFrequencyPer90Days = () => {
    if (!data.charts.tasksFrequencyPer90Days || data.charts.tasksFrequencyPer90Days.length === 0) {
      return [];
    }

    const range = [];
    const dataMap = new Map();
    const dateTo = moment(moment().startOf('day').subtract(90, "days")).startOf('day')

    for (let i = 0; i < data.charts.tasksFrequencyPer90Days.length; i++) {
      const [count, date] = Object.values(data.charts.tasksFrequencyPer90Days[i]);
      dataMap.set(moment(date).toDate().getTime(), count)
    }

    for (let i = 0; i <= 90; i++) {
      const delta = dateTo.clone().add(i, "day").toDate().getTime()

      range.push({
        x: delta,
        y: dataMap.get(delta) || 0
      });
    }

    return range;
  }

  const processTasksFrequencyPerDay = () => {
    if (!data.charts.tasksFrequencyPerDay || data.charts.tasksFrequencyPerDay.length === 0) {
      return [];
    }

    const range = [];
    const dataMap = new Map();
    const startDate = moment().startOf("day");

    for (let i = 0; i < data.charts.tasksFrequencyPerDay.length; i++) {
      const [count, time] = Object.values(data.charts.tasksFrequencyPerDay[i]);
      dataMap.set(moment(time, "HH:mm:ss").toDate().getTime(), count)
    }

    for (let i = 0; i < 24; i++) {
      const delta = startDate.clone().add(i, "hour").toDate().getTime();

      range.push({
        x: delta,
        y: dataMap.get(delta) || 0,
      });
    }

    return range;
  }

  const processGenderCount = () => {
    if (!data.analytics.gender_counts || (!data.analytics.gender_counts.female && data.analytics.gender_counts.female !== 0)) {
      return [];
    }

    return [{
      "name": "Male",
      "value": data.analytics.gender_counts.male || 0
    }, {
      "name": "Female",
      "value": data.analytics.gender_counts.female || 0
    }]
  }

  const processAgeGroup = () => {
    if (!data.analytics.age_counts || data.analytics.age_counts.length === 0) {
      return [];
    }

    const range = []

    for (let i = 0; i < data.analytics.age_counts.length; i++) {
      range.push({
        "value": data.analytics.age_counts[i] || 0,
        "name": data.analytics.age_names[i],
      });
    }
    return range
  }

  const processWorkingHours = () => {
    if (!data.analytics.day_hour || data.analytics.day_hour.length === 0) {
      return [];
    }

    const range = []

    for (let i = 0; i < data.analytics.day_hour.length; i++) {
      const dayData = data.analytics.day_hour[i]
      let hour = dayData[1]
      let day = dayData[0]

      const existedRange = range.find((item) => item.hour === hour && item.weekday === day)
      if (!existedRange) {
        range.push({
          hour: hour,
          weekday: day,
          value: 1
        })
      } else {
        existedRange.value += 1
      }
    }

    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]

    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < weekdays.length; j++) {
        const day = weekdays[j]
        const hour = i

        const existedRange = range.find((item) => item.hour.toString() === hour.toString() && item.weekday.toLowerCase() === day.toLowerCase())
        if (!existedRange) {
          range.push({
            hour: hour + "",
            weekday: day,
            value: 0
          })
        }
      }
    }

    return range
  }

  const processTaskPeriod = () => {
    if (!data.analytics.month_names) {
      return [];
    }

    const taskPeriodData = []
    const range = []

    for (const [key, value] of Object.entries(data.analytics.month_names)) {
      taskPeriodData.push({
        "index": value || 0,
        "name": key,
      });
    }

    taskPeriodData.sort((a, b) => a.index - b.index)

    for (let i = 0; i < taskPeriodData.length; i++) {
      range.push({
        "value": data.analytics.month_counts[i],
        "name": taskPeriodData[i].name,
      });
    }

    return range
  }

  useEffect(() => {
    if (loadingQueries.length === 0) {
      setChartsData({
        ...chartsData,
        minutesRecorded: processMinutesRecorded(),
        subjectsPerGender: processSubjectsPerGender(),
        subjectsPerAgeGroup: processSubjectsPerAgeGroup(),
        tasksFrequencyPer90Days: processTasksFrequencyPer90Days(),
        tasksFrequencyPerDay: processTasksFrequencyPerDay(),
        taskPeriod: processTaskPeriod(),
        workingHours: processWorkingHours(),
        ageGroup: processAgeGroup(),
        genderCount: processGenderCount(),
      });
    }
  }, [data.charts, data.analytics]);

  const printLoading = () => {
    if (loadingQueries.length > 0) {
      return (
        <div className={"chart-overlay w-100 h-100 d-flex align-items-center justify-content-center"}
             style={{zIndex: 100}}></div>
      )
    }
    return null
  }

  if (taskPage) {
    if (activeChart.id !== TASK_CHART_CHARTS) {
      return null
    }

    return (
      <>
        <GradeCharts
          className={className}
          printLoading={printLoading}
          data={data}
        />
        <SpectrumCharts
          className={className}
          printLoading={printLoading}
          data={data}
        />
      </>
    );
  }

  return (
    <>
      <DurationChart
        printLoading={printLoading}
        className={className}
        data={data.analytics.tasks}
      />
      <DivergentCharts
        className={className}
        printLoading={printLoading}
        data={data}
      />
      <DurationChart
        printLoading={printLoading}
        className={className}
        data={data.analytics.projects}
      />
      <DurationChart
        printLoading={printLoading}
        className={className}
        data={data.analytics.subjects}
      />
      <Row xs={1} xxl={2} className={`${className}`} style={{margin: "1rem 1rem 0rem", position: 'relative'}}>
        {printLoading()}

        {chartsData.ageGroup.length > 0 && <Col className="mb-1 redesign-card">
          <GrainyBarChartAM5
            title="Age"
            valueField="value"
            categoryField="name"
            data={chartsData.ageGroup}
            colors={[
              BLUE_COLOR,
              BLUE_COLOR,
              BLUE_COLOR,
              BLUE_COLOR,
              BLUE_COLOR,
              BLUE_COLOR,
              BLUE_COLOR,
            ]}
          />
        </Col>}

        {chartsData.genderCount.length > 0 && <Col className="mb-1 ml-5 redesign-card">
          <DonutChartAM5
            title="Gender"
            valueField="value"
            categoryField="name"
            data={chartsData.genderCount}
            colors={[
              BLUE_COLOR,
              ORANGE_COLOR,
            ]}
          />
        </Col>}

        {chartsData.taskPeriod.length > 0 && <Col className="mb-1 redesign-card">
          <GrainyBarChartAM5
            title="Tasks"
            valueField="value"
            categoryField="name"
            data={chartsData.taskPeriod}
            colors={[
              ORANGE_COLOR,
              ORANGE_COLOR,
              ORANGE_COLOR,
              ORANGE_COLOR,
              ORANGE_COLOR,
              ORANGE_COLOR,
            ]}
          />
        </Col>}

        {chartsData.tasksFrequencyPer90Days.length > 0 && <Col className="mb-1 ml-5 redesign-card">
          <AreaChartAM5
            unit="day"
            title="Tasks"
            valueField="y"
            categoryField="x"
            data={chartsData.tasksFrequencyPer90Days}
          />
        </Col>}

      </Row>
      <Row xs={1} xxl={1} className={`${className} redesign-card`}
           style={{margin: "0rem 1rem 0rem", position: 'relative'}}>
        {printLoading()}

        {chartsData.workingHours.length > 0 && <HeatMapChartAM5
          title="Working Hours"
          valueField="value"
          categoryFieldX="hour"
          categoryFieldY="weekday"
          data={chartsData.workingHours}
        />}
      </Row>
      <Row xs={1} xxl={2} className={`${className}`} style={{margin: "1rem 1rem 0rem", position: 'relative'}}>
        {printLoading()}

        {false && chartsData.minutesRecorded.length > 0 && <Col className="mb-1 redesign-card">
          <PieChartAM5
            title="Duration"
            categoryField="name"
            valueField="duration"
            data={chartsData.minutesRecorded}
            showLegend={true}
          />
        </Col>}

        {false && chartsData.subjectsPerGender.length > 0 &&
          <Col className="mb-1 redesign-card">
            <PieChartAM5
              title="Gender"
              categoryField="name"
              valueField="count"
              data={chartsData.subjectsPerGender}
              showLegend={true}
            />
          </Col>}

        {false && chartsData.subjectsPerAgeGroup.length > 0 &&
          <Col className="mb-1 redesign-card">
            <BarChartAM5
              title="Age"
              showLegend={false}
              valueField="value"
              categoryField="category"
              data={chartsData.subjectsPerAgeGroup}
            />
          </Col>}

        {chartsData.tasksFrequencyPerDay.length > 0 &&
          <Col className="mb-1 redesign-card">
            <AreaChartAM5
              unit="hour"
              tooltipFormat={'HH:mm'}
              title="Working hours"
              valueField="y"
              categoryField="x"
              data={chartsData.tasksFrequencyPerDay}
            />
          </Col>}
      </Row>
    </>
  );
};

export default DashboardCharts;
