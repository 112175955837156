import axios from "axios";
import { Service } from "axios-middleware";
import errorHandler from "./middlewares/errorHandler";
import authorization from "./middlewares/authorization";
import addBaseUrl from "./middlewares/addBaseUrl";

const service = new Service(axios);

service.register({
  onRequest(config) {
    config = addBaseUrl(config);
    config = authorization(config);

    return config;
  },
  onSync(promise) {
    return promise;
  },
  onResponse(response) {
    return response;
  },
  onResponseError(err) {
    return errorHandler(err);
  },
});

export default axios;
