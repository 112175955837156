import { connect } from "react-redux";
import { Children, useState } from "react";
import { PROJECT } from "../utils/constants";
import ProjectsList from "../components/projectsList";
import { createProject } from "../store/slices/projects";
import ItemsContainer from "../components/itemsContainer";
import IconButton from "../components/common/iconButton";
import AddNewItem from "../components/listItem/addNewItem";

const ProjectsListPage = ({
  createProject,
}) => {
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  return (
    <ItemsContainer
      title="Projects"
      actionButtons={
        Children.toArray([
          <IconButton
            icon="sliders"
            onClick={() => setIsOptionsVisible(!isOptionsVisible)}
          />,
          <AddNewItem
            source={PROJECT}
            onSubmit={createProject}
          />
        ])
      }
    >
      <ProjectsList 
        isOptionsVisible={isOptionsVisible}
        indexedFields={["name", "description"]}
      />
    </ItemsContainer>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    createProject: (project) => dispatch(createProject(project)),
  };
}

function mapStateToProps(state) {
  const { additionalData } = state.entities.projects;
  return { additionalData };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsListPage);
