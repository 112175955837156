import { Spinner } from "react-bootstrap";
import IconButton from "../../common/iconButton";

export const Chart = ({
  id,
  height,
  isEmpty,
  isLoading,
  legendData,
  emptyStateMessage
}) => {
  return (
    <>
      <div style={{ height, position: "relative" }} className="mt-3">
        <div id={id} className={"h-100"}></div>

        {isLoading && (
          <div className={"chart-overlay w-100 h-100 d-flex align-items-center justify-content-center"}>
            <Spinner animation="border" />
          </div>
        )}

        {legendData.paginatorVisible && (
          <div className={"chart-overlay"} style={{ 
            right: 0,
            paddingLeft: 8,
            paddingRight: 8,
            backgroundColor: "transparent",
            top: legendData.size.height - 16,
            width: legendData.size.width + 10,
          }}>
            <div className="d-flex justify-content-space-between align-items-center">
              <IconButton
                onClick={() => legendData.handlePagePrev()}
                icon="chevron-left"
                size="small"
                isDisabled={legendData.currentPage === 1}
              />

              <span style={{ fontSize: 12 }}> {legendData.currentPage} / {legendData.pages} </span>

              <IconButton
                onClick={() => legendData.handlePageNext()}
                icon="chevron-right"
                size="small"
                isDisabled={legendData.currentPage === legendData.pages}
              />
            </div>
          </div>
        )}

        {isEmpty && !isLoading && (
          <div className={"chart-overlay w-100 h-100 d-flex align-items-center justify-content-center"}>
            <span className={"text-white"}>
              {emptyStateMessage}
            </span>
          </div>
        )}
      </div>
    </>
  );
}