import * as am5 from "@amcharts/amcharts5";
import { useRef, useLayoutEffect } from 'react';
import * as am5XY from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {BLUE_COLOR} from '../dashboard/utils/colors'

const ClusteredBarChartAM5 = ({ title, data, categoryField, valueField }) => {
    const chartRef = useRef(null);
    const containerRef = useRef(null);

    useLayoutEffect(() => {
        const root = am5.Root.new(containerRef.current);
        const darkTheme = am5themes_Dark.new(root);
        const responsiveTheme = am5themes_Responsive.new(root);
        const animatedTheme = am5themes_Animated.new(root);

        root.setThemes([animatedTheme, darkTheme, responsiveTheme]);
        root._logo.dispose();

        const chart = root.container.children.push(
            am5XY.XYChart.new(root, {
                panX: false,
                panY: false,
                paddingLeft:0,
                layout: root.verticalLayout
            })
        );

        const cursor = chart.set("cursor", am5XY.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);

        const yRenderer = am5XY.AxisRendererY.new(root, {
            inversed: true,
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
            minorGridEnabled: true,
            minGridDistance: 10,
            strokeOpacity: 1,
            strokeWidth: 2,
            stroke: am5.color("#FFFFFF")
        });

        yRenderer.grid.template.setAll({ location: 0.5 });

        responsiveTheme.addRule({
            name: "AxisRendererX",
            relevant: (width, height) => width < 350,
            applying: () => yRenderer.labels.template.setAll({ rotation: -45, location: 0.25 }),
            removing: () => yRenderer.labels.template.setAll({ rotation: 0, location: 0.5 })
        });

        var yAxis = chart.yAxes.push(am5XY.CategoryAxis.new(root, {
            categoryField: categoryField,
            renderer: yRenderer,
        }));

        yAxis.data.setAll(data);

        var xAxis = chart.xAxes.push(am5XY.ValueAxis.new(root, {
            renderer: am5XY.AxisRendererX.new(root, {
                strokeOpacity: 0.5,
                minGridDistance: 50,
            }),
            min: 0
        }));

        yAxis.get("renderer").labels.template.setup = function(target) {
            target.set("fill", am5.color("#BDBDBD"));
            target.set("fontSize", 12);
        };
        xAxis.get("renderer").labels.template.setup = function(target) {
            target.set("fill", am5.color("#BDBDBD"));
            target.set("fontSize", 12);
        };

        var series = chart.series.push(am5XY.ColumnSeries.new(root, {
            name: title,
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: valueField,
            categoryYField: categoryField,
            fill: BLUE_COLOR,
            sequencedInterpolation: true,
            tooltip: am5.Tooltip.new(root, {
                pointerOrientation: "horizontal",
                labelText: "[bold]{name}[/]\n{categoryY}: {valueX}"
            }),
        }));

        series.columns.template.setAll({
            height: am5.p50,
            strokeOpacity: 0,
            cornerRadiusBR: 10,
            cornerRadiusTR: 10,
        });

        series.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationX: 1,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    centerY: am5.p50,
                    text: "{valueX}",
                    populateText: true,
                    fontSize: 8,
                })
            });
        });

        series.bullets.push(function () {
            return am5.Bullet.new(root, {
                locationX: 1,
                locationY: 0.5,
                sprite: am5.Label.new(root, {
                    centerX: am5.p100,
                    centerY: am5.p50,
                    // text: "{name}",
                    text: "",
                    fill: am5.color(0xffffff),
                    populateText: true
                })
            });
        });

        series.data.setAll(data);

        chartRef.current = chart;

        chart.appear(1000, 100);

        return () => {
            root.dispose();
        }
    }, [data]);

    return (
        <div>
            {title && <p className="text-larger font-weight-bold text-center"> {title} </p>}
            <div ref={containerRef} style={{ minHeight: 250 }}></div>
        </div>
    )
};

export default ClusteredBarChartAM5;
