import { Search } from "../search";
import SortBy from "../sortBy";
import EditProject from "../editProject";
import { Col, Row } from "react-bootstrap";
import ListItem from "../listItem/listItem";
import { useSort } from "../../hooks/useSort";
import { PROJECT } from "../../utils/constants";
import DeleteItem from "../listItem/deleteItem";
import { useSearch } from "../../hooks/useSearch";
import { useEffect, Children, useState } from "react";
import LoadingComponent from "./../loadingComponent/index";

const ProjectsList = ({
  isLoading,
  projects,
  getProjects,
  deleteProject,
  shouldUpdate,
  setDashboardFilter,
  isOptionsVisible,
  indexedFields
}) => {
  const [items, setItems] = useState(projects);
  const { sortBy, sortData } = useSort(indexedFields);
  const { search, searchValue } = useSearch(indexedFields);

  useEffect(() => {
    getProjects();
    setDashboardFilter({ selectedProjectId: "", selectedSubjectId: "", selectedTaskId: "" });
  }, [shouldUpdate, getProjects, setDashboardFilter]);

  useEffect(() => {
    const items = projects.map((project) => ({ ...project }));
    setItems(sortBy(search(items, searchValue), sortData));
  }, [projects, search, searchValue, sortBy, sortData]);

  return (
    <div className="text-white d-flex flex-column gap-3">
      {isLoading && <LoadingComponent />}

      {!isLoading && isOptionsVisible &&
        <Row className="filters" style={{ rowGap: "16px" }}>
          <Col sm={4}>
            <SortBy
              keys={indexedFields}
              sortData={sortData}
              onSort={(sortData) => setItems(sortBy(items, sortData))}
            />
          </Col>

          <Col sm={{ span: 6, offset: 2 }}>
            <Search
              searchValue={searchValue}
              onSearch={(value) => setItems(search(sortBy(projects, sortData), value))}
            />
          </Col>
        </Row>
      }

      {!isLoading && (
        <>
          {items.map((project) => (
            <ListItem
              item={project}
              title={project.name}
              description={project.description}
              source={PROJECT}
              key={project.objectId}
              actionButtons={Children.toArray([
                <EditProject item={project} />,
                <DeleteItem
                  id={`delete-${PROJECT}-${project.objectId}`}
                  handleDeleteItem={() => deleteProject(project.objectId)}
                  text="removeProjectConfirmText"
                />
              ])}
            />
          ))}
        </>
      )}

      {(!isLoading && !searchValue && (!items || items.length === 0)) && <p className="items-container-empty"> No projects. Click "+" to create a project. </p>}
      {searchValue && !items.length && <p className="items-container-empty"> No projects found. </p>}
    </div>
  );
};

export default ProjectsList;
