import React from "react";
import { Spinner } from "react-bootstrap";

const InfoCell = ({ value, title, isLoading, valueSize = 52, titleSize = 24 }) => {
  return (
    <div className="text-center">
      <div>
        {isLoading &&
          <div style={{ lineHeight: 1.2, fontSize: valueSize, marginBottom: 8 }}>
            <Spinner animation="border" variant="secondary" as="h3" />
          </div>
        }
        {(!isLoading && value !== undefined) && <h1 className="text-strong" style={{ fontSize: valueSize }}> {value} </h1>}
      </div>

      <h4 className="text-medium" style={{ fontSize: titleSize }}> {title} </h4>
    </div>
  );
};

export default InfoCell;