import moment from "moment";

export const capitalize = (content) => {
  return content.charAt(0).toUpperCase() + content.slice(1);
};

export const humanize = (content) => {
  const tokens = content.split(/(?=[A-Z])/);
  return capitalize(tokens[0]) + " " + tokens.slice(1).map((token) => token.toLowerCase()).join(" ");
} 

export const formatMillisecondsDuration = (duration) => {
  const seconds = Math.floor((duration / 1000) % 60).toString().padStart(2, "0");
  const minutes = Math.floor((duration / (1000 * 60)) % 60).toString().padStart(2, "0");
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24).toString().padStart(2, "0");
  return `${hours !== "00" ? hours + ":" : ""}${minutes}:${seconds}`
}

export const formatTaskDuration = (duration) => {
  const hours   = Math.floor(duration / 3600).toString().padStart(2, "0");
  const minutes = Math.floor((duration - (hours * 3600)) / 60).toString().padStart(2, "0");
  const seconds = (duration - (hours * 3600) - (minutes * 60)).toString().padStart(2, "0");
  return `${hours}h:${minutes}m:${seconds}s`;
}

export const parseDurationToMilliseconds = (durationString) => {
  return moment.duration(durationString, "millisecond").asMilliseconds();
}