import { Link } from "react-router-dom";
import IconButton from "../common/iconButton";
import { capitalize } from "../../utils/stringUtils";

const SubjectDetailsHeader = ({ subject, project, href, handleOptionsToggle, className="" }) => {
  return (
    <>
      {subject && (
        <div className={`items-container-heading ${className}`} key={subject.objectId}>
          <div className="d-flex justify-content-space-between">
            <div className="w-100">
              <div className="d-flex align-items-center justify-content-space-between">
                <div className="d-flex align-items-baseline text-white text-medium gap-2">
                  {href && <Link to={href} className="text-link text-heading"> Subjects </Link>}
                  <span> / </span>
                  <span className="text-link"> {subject.name} </span>
                </div>

                {handleOptionsToggle && (
                  <IconButton
                    icon="sliders"
                    onClick={() => handleOptionsToggle()}
                  />
                )}
              </div>
              <p className="text-paragraph text-initial"> {capitalize(subject.gender)}, {subject.age} y.o., {capitalize(subject.handedness)} handed </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { SubjectDetailsHeader };
export default SubjectDetailsHeader;