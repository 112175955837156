import { useState } from "react";
import IconButton from "../common/iconButton";
import { withTranslation } from "react-i18next";
import ConfirmRemoveModal from "../confirmRemoveModal";

const DeleteItem = ({ id, handleDeleteItem, text, t, }) => {
  const [show, setShow] = useState(false);

  const handleDelete = () => {
    setShow(false);
    handleDeleteItem();
  };

  return (
    <>
      <IconButton
        id={id}
        icon="times"
        size="small"
        onClick={() => setShow(true)}
      />
      <ConfirmRemoveModal
        show={show}
        text={t(text)}
        handleClose={() => setShow(false)}
        handleDelete={handleDelete}
      />
    </>
  );
}

export default withTranslation()(DeleteItem);