import Profile from "./profile";
import { connect } from "react-redux";
import {
  editProfile,
  getCurrentUser,
} from "../../store/slices/user";

function mapStateToProps(state) {
  const { shouldUpdate, isLoggedIn, currentUser: user } = state.entities.user;
  return { shouldUpdate, user, isLoggedIn };
}

function mapDispatchToProps(dispatch) {
  return {
    editProfile: (data) => dispatch(editProfile(data)),
    getCurrentUser: () => dispatch(getCurrentUser()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
