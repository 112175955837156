import moment from "moment";
import { useCallback, useEffect, useRef } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import { ObjectUtils } from "../utils/objectUtils";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";

export const useChart = ({
  data,
  chartId,
  legendId,
  config = {}
}) => {
  const chartRef = useRef({
    chart: null,
    root: null,
    xAxis: null,
    yAxis: null,
    legend: null,
    legendRoot: null,
    series: [],
  });

  const createChart = useCallback(() => {
    dispose();
    const root = am5.Root.new(chartId);
    root.setThemes([am5themes_Dark.new(root)]);
    root._logo.dispose();

    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: true,
        focustable: true,
        wheelY: "zoomX"
      })
    );

    const xAxis = chart.xAxes.push(
      am5xy.DateAxis.new(root, {
        groupData: false,
        tooltipDateFormat: "HH:mm:ss:SSS",
        baseInterval: { timeUnit: "millisecond", count: 100 },
        tooltip: am5.Tooltip.new(root, {}),
        renderer: am5xy.AxisRendererX.new(root, {
          minGridDistance: 75
        })
      })
    );

    xAxis.get("dateFormats")["second"] = "HH:mm:ss";
    xAxis.get("dateFormats")["millisecond"] = "HH:mm:ss:SSS";
    xAxis.get("dateFormats")["minute"] = "HH:mm:ss";

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        extraTooltipPrecision: 1,
        tooltip: am5.Tooltip.new(root, {}),
        renderer: am5xy.AxisRendererY.new(root, {}),
        ...config?.yAxis
      })
    );

    chart.set("cursor", am5xy.XYCursor.new(root, {
      behavior: "none",
      xAxis: xAxis,
      yAxis: yAxis,
    }));

    chart.set("scrollbarX", am5.Scrollbar.new(root, {
      orientation: "horizontal",
      marginBottom: 24,
    }))

    chartRef.current = { 
      chart,
      root,
      xAxis,
      yAxis,
      series: [],
      legend: null,
      legendRoot: null
    };

    return chart;
  }, [
    chartId,
    config?.yAxis,
  ]);

  const addSeries = useCallback((key, seriesData, name) => {
    const { chart, root, xAxis, yAxis } = chartRef.current;

    if (!chart) {
      console.warn("Could not add series data, chart is not initialized.");
      return;
    }

    const series = chart.series.push(
      am5xy.LineSeries.new(root, {
        name: name,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        valueXField: "date",
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "{name}: {valueY}"
        }),
      })
    );

    series.data.setAll(seriesData);
    chartRef.current.series.push({ key, series });
  }, []);

  const addData = useCallback((seriesKey, time, value) => {
    const { series } = chartRef.current;
    const point = { value, date: moment().startOf("day").add(time, "ms").valueOf() };
    let seriesByKey = (series.find((item) => item.key === seriesKey) || {})?.series;

    if (!seriesByKey) {
      addSeries(seriesKey, [], seriesKey);
      seriesByKey = (series.find((item) => item.key === seriesKey) || {})?.series;
    }

    const seriesDataIndex = ObjectUtils.getInsertionIndex(seriesByKey.data.values, point, "date");
    seriesByKey.data.insertIndex(seriesDataIndex, point);
  }, [addSeries]);

  const drawChart = useCallback(({ onlyKeys = [], useUppercaseKeys = false }) => {
    createChart();

    data.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (onlyKeys.includes(key)) {
          const keyRepr = useUppercaseKeys ? key[0].toUpperCase() + key.slice(1) : key
          addData(keyRepr, item["time"], item[key]);
        }
      });
    });
  }, [addData, createChart, data]);

  const updateLegend = useCallback(() => {
    if (chartRef.current.legend) {
      chartRef.current.legend.dispose();
      chartRef.current.legendRoot.dispose();
      chartRef.current.legend = null;
      chartRef.current.legendRoot = null;
    }

    const root = am5.Root.new(legendId);
    const legend = root.container.children.push(
      am5.Legend.new(root, {
        useDefaultMarker: true,
        width: am5.percent(100),
        height: am5.percent(100),
        centerX: am5.percent(50),
        x: am5.percent(50),
        verticalScrollbar: am5.Scrollbar.new(root, {
          orientation: "vertical",
        }),
      })
    );

    legend.labels.template.setAll({
      fill: am5.color(0xDDDDDD),
    });

    legend.markers.template.setup = (marker) => {
      const check = am5.Graphics.new(root, {
        fillOpacity: 1,
        width: 20,
        height: 20,
        layer: 50
      });

      check.states.create("disabled", { fillOpacity: 0 });
      marker.children.push(check);
    };

    legend.data.setAll([]);
    legend.data.setAll(chartRef.current.chart.series.values);
    chartRef.current.legend = legend;
    chartRef.current.legendRoot = root;
  }, [legendId]);

  const dispose = () => {
    chartRef.current.root?.dispose();
    chartRef.current.legend?.dispose();
    chartRef.current.legendRoot?.dispose();
    chartRef.current.chart?.dispose();
    chartRef.current.xAxis?.dispose();
    chartRef.current.yAxis?.dispose();
    chartRef.current = {
      chart: null,
      root: null,
      xAxis: null,
      yAxis: null,
      legend: null,
      legendRoot: null,
      series: []
    };
  }

  useEffect(() => {
    return () => dispose();
  }, []);

  return {
    addData,
    drawChart,
    updateLegend,
    getSeries: () => chartRef.current.series,
    getAxisX: () => chartRef.current.xAxis,
    getRoot: () => chartRef.current.root
  }
}