export class ObjectUtils {
    /**
     * @param   {Array<Object>} list    Sorted list of objects to search in.
     * @param   {Object}        item    Item to be inserted.
     * @param   {string}        key     Attribute to compare by.
     * @returns {number}                Index at which given item should be inserted.
     */
    static getInsertionIndex(list, item, key) {
        let low = 0, high = list.length;

        while (low < high) {
            const middle = (low + high) >>> 1;

            if (list[middle][key] < item[key])
                low = middle + 1;

            else high = middle;
        }

        return low;
    }
}