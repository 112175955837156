import { useEffect } from "react";
import { Form, Formik } from "formik";
import { connect, useSelector } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { sendForgotEmail, resetStateForgotPassword } from "../store/slices/auth";
import { FormField } from "../components/form/formField";

const ForgotPassword = ({ sendForgotEmail, resetStateForgotPassword, currentUser }) => {
  const { isForgotPasswordDone, isLoading, errors: serverErrors } = useSelector((state) => state.entities.auth);

  useEffect(() => {
    return () => {
      resetStateForgotPassword();
    }
  }, [resetStateForgotPassword]);

  if (!!currentUser) {
    return (<Redirect to={{ pathname: "/" }} />);
  }

  return (
    <Container fluid className="h-100">
      <Row className="h-100 align-content-center px-5" xs={1} sm={1} md={2} lg={2}>
        <Col className="flex align-items-center justify-content-center">
            <Formik
              initialValues={{ email: "" }}
              onSubmit={(values) => sendForgotEmail(values)}
            >
              {({ errors: formErrors, touched }) => (
                <Form className="form compact flex flex-direction-column">
                  {!isForgotPasswordDone && (
                    <>
                      <FormField 
                        name="email"
                        type="email"
                        required={true}
                        labelName="Email"
                        clientErrors={formErrors}
                        serverErrors={serverErrors}
                        touched={touched}
                      />

                      <p className="text-white">
                        Please enter your email address. You will receive an email message with instructions on how to reset your password.
                      </p>

                      <button 
                        className="button wide align-self-end"
                        disabled={isLoading}
                      >
                        {isLoading ? "Sending..." : "Send"}
                      </button>
                    </>
                  )}

                  {isForgotPasswordDone && (
                    <p className="text-white">
                      Password recovery link has been sent to your email. Please follow the instructions.
                    </p>
                  )}

                  <p className="text-white align-self-end">
                    <Link className="link" to="/account/login"> Sign in </Link>
                  </p>
                </Form>
              )}
            </Formik>
        </Col>

        <Col className="flex align-items-center justify-content-center" xs={{ order: "first" }} sm={{ order: "first" }} md={{ order: "last" }} lg={{ order: "last" }}>
          <img src="/images/register-illustration.svg" alt="login" style={{ width: "100%", height: "auto", minWidth: 200 }} />
        </Col>
      </Row>
    </Container>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    sendForgotEmail: (data) => dispatch(sendForgotEmail(data)),
    resetStateForgotPassword: () => dispatch(resetStateForgotPassword())
  };
}

export default connect(null, mapDispatchToProps)(ForgotPassword);
