import * as am5 from "@amcharts/amcharts5";
import {useRef, useLayoutEffect} from 'react';
import * as am5XY from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {BASELINE_COLOR, POOR_COLOR, SUFFICIENT_COLOR} from '../dashboard/utils/colors'

const DivergentStackedChartAM5 = ({title, data, categoryField}) => {
  const chartRef = useRef(null);
  const containerRef = useRef(null);

  useLayoutEffect(() => {
    const root = am5.Root.new(containerRef.current);
    const darkTheme = am5themes_Dark.new(root);
    const responsiveTheme = am5themes_Responsive.new(root);
    const animatedTheme = am5themes_Animated.new(root);

    root.setThemes([animatedTheme, darkTheme, responsiveTheme]);
    root._logo.dispose();

    const chart = root.container.children.push(
      am5XY.XYChart.new(root, {
        panX: false,
        panY: false,
        layout: root.horizontalLayout,
        arrangeTooltips: false,
        paddingLeft: 0
      })
    );

    root.numberFormatter.set("numberFormat", "#.#s %");

    const yAxis = chart.yAxes.push(
      am5XY.CategoryAxis.new(root, {
        categoryField: categoryField,
        renderer: am5XY.AxisRendererY.new(root, {
          inversed: true,
          cellStartLocation: 0.3,
          cellEndLocation: 0.7,
          minorGridEnabled: true,
          minGridDistance: 1,
          strokeOpacity: 1,
          strokeWidth: 1,
          stroke: am5.color("#FFFFFF")
        })
      })
    );

    yAxis.data.setAll(data);

    const xAxis = chart.xAxes.push(
      am5XY.ValueAxis.new(root, {
        calculateTotals: true,
        min: -100,
        max: 100,
        renderer: am5XY.AxisRendererX.new(root, {
          minGridDistance: 50
        })
      })
    );

    const xRenderer = yAxis.get("renderer");
    xRenderer.axisFills.template.setAll({
      fill: am5.color(0x000000),
      fillOpacity: 0.05,
      visible: true
    });

    yAxis.get("renderer").labels.template.setup = function(target) {
      target.set("fill", am5.color("#BDBDBD"));
      target.set("fontSize", 12);
    };
    xAxis.get("renderer").labels.template.setup = function(target) {
      target.set("fill", am5.color("#BDBDBD"));
      target.set("fontSize", 12);
    };

    function createSeries(field, name, color) {
      const series = chart.series.push(
        am5XY.ColumnSeries.new(root, {
          xAxis: xAxis,
          yAxis: yAxis,
          name: name,
          valueXField: field,
          categoryYField: categoryField,
          sequencedInterpolation: true,
          stacked: true,
          fill: color,
          stroke: color,
          calculateAggregates: true,
        })
      );

      series.columns.template.setAll({
        height: am5.p100
      });

      series.bullets.push(function (root, series) {
        return am5.Bullet.new(root, {
          locationX: 0.5,
          locationY: 0.5,
          sprite: am5.Label.new(root, {
            fill: am5.color(0xffffff),
            centerX: am5.p50,
            centerY: am5.p50,
            text: "{valueX}",
            populateText: true,
            oversizedBehavior: "hide"
          })
        });
      });

      series.data.setAll(data);
    }

    createSeries("poor", "Poor", POOR_COLOR);
    createSeries("baseline", "Baseline", BASELINE_COLOR);
    createSeries("sufficient", "Sufficient", SUFFICIENT_COLOR);

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerY: am5.p50,
        y: am5.p50,
        layout: root.verticalLayout,
        marginLeft: 50,
      })
    );

    legend.data.setAll(chart.series.values);

    chartRef.current = chart;
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    }
  }, [data]);

  return (
    <div>
      {title && <p className="text-larger font-weight-bold text-center"> {title} </p>}
      <div ref={containerRef} style={{minHeight: 250}}></div>
    </div>
  )
};

export default DivergentStackedChartAM5;
