import jwtDecode from "jwt-decode";
import { TOKEN_KEY } from "../store/constants";

export function logout() {
  localStorage.removeItem(TOKEN_KEY);
}

export function getCurrentUser() {
  try {
    const jwt = localStorage.getItem(TOKEN_KEY);
    return jwtDecode(jwt);
  } catch (ex) {
    return null;
  }
}

export function isTokenValid() {
  const jwt = localStorage.getItem(TOKEN_KEY);
  
  if (!jwt) {
    return false;
  }
  
  try {
    const expTime = jwtDecode(jwt).exp;

    if (expTime * 1000 < Date.now()) {
      return false;
    }

    return true;
  } catch (error) {
    console.error("Could not validate JWT:", error);
    return false;
  }
}

export function isLoggedIn() {
  const token = localStorage.getItem("token");
  const user = getCurrentUser();
  return isTokenValid(token) && user;
}

export function getUserId() {
  const jwt = localStorage.getItem(TOKEN_KEY);

  try {
    const userId = jwtDecode(jwt).user.objectId;
    return userId;
  } catch (error) {
    console.error("Could not decode JWT:", error);
  }
}

const exportedObject = {
  logout,
  getCurrentUser,
  isTokenValid,
};

export default exportedObject;
