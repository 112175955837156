import * as am5 from "@amcharts/amcharts5";
import { useRef, useLayoutEffect } from 'react';
import * as am5XY from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {BLUE_COLOR, ORANGE_COLOR} from '../dashboard/utils/colors'

const HeatMapAM5 = ({ title, data, valueField, categoryFieldX, categoryFieldY, legends }) => {
    const chartRef = useRef(null);
    const containerRef = useRef(null);

    useLayoutEffect(() => {
        const root = am5.Root.new(containerRef.current);
        const darkTheme = am5themes_Dark.new(root);
        const responsiveTheme = am5themes_Responsive.new(root);
        const animatedTheme = am5themes_Animated.new(root);

        root.setThemes([animatedTheme, darkTheme, responsiveTheme]);
        root._logo.dispose();

        const chart = root.container.children.push(am5XY.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "none",
            wheelY: "none",
            paddingLeft: 0,
            layout: root.verticalLayout
        }));

        const yRenderer = am5XY.AxisRendererY.new(root, {
            visible: false,
            minGridDistance: 20,
            inversed: true,
            minorGridEnabled: true
        });

        yRenderer.grid.template.set("visible", false);

        const yAxis = chart.yAxes.push(am5XY.CategoryAxis.new(root, {
            maxDeviation: 0,
            renderer: yRenderer,
            categoryField: categoryFieldY
        }));

        const xRenderer = am5XY.AxisRendererX.new(root, {
            visible: false,
            minGridDistance: 30,
            opposite:true,
            minorGridEnabled: true
        });

        xRenderer.grid.template.set("visible", false);

        const xAxis = chart.xAxes.push(am5XY.CategoryAxis.new(root, {
            renderer: xRenderer,
            categoryField: categoryFieldX
        }));

        yAxis.get("renderer").labels.template.setup = function(target) {
            target.set("fill", am5.color("#BDBDBD"));
            target.set("fontSize", 12);
        };
        xAxis.get("renderer").labels.template.setup = function(target) {
            target.set("fill", am5.color("#BDBDBD"));
            target.set("fontSize", 12);
        };

        const series = chart.series.push(am5XY.ColumnSeries.new(root, {
            calculateAggregates: true,
            stroke: am5.color("#FFFFFF"),
            clustered: false,
            xAxis: xAxis,
            yAxis: yAxis,
            categoryXField: categoryFieldX,
            categoryYField: categoryFieldY,
            valueField: valueField
        }));

        series.columns.template.setAll({
            tooltipText: "{value}",
            strokeOpacity: 1,
            strokeWidth: 0.2,
            width: am5.percent(100),
            height: am5.percent(100)
        });

        series.columns.template.events.on("pointerover", function(event) {
            const di = event.target.dataItem;
            if (di) {
                heatLegend.showValue(di.get("value", 0));
            }
        });

        series.events.on("datavalidated", function() {
            heatLegend.set("startValue", series.getPrivate("valueHigh"));
            heatLegend.set("endValue", series.getPrivate("valueLow"));
        });

        series.set("heatRules", [{
            target: series.columns.template,
            min: BLUE_COLOR,
            max: ORANGE_COLOR,
            dataField: "value",
            key: "fill"
        }]);

        const heatLegend = chart.bottomAxesContainer.children.push(am5.HeatLegend.new(root, {
            orientation: "horizontal",
            endColor: BLUE_COLOR,
            startColor: ORANGE_COLOR
        }));

        series.data.setAll(data);
        yAxis.data.setAll([
            { weekday: "Sun" },
            { weekday: "Mon" },
            { weekday: "Tue" },
            { weekday: "Wed" },
            { weekday: "Thu" },
            { weekday: "Fri" },
            { weekday: "Sat" }
        ]);

        xAxis.data.setAll([
            { hour: "0" },
            { hour: "1" },
            { hour: "2" },
            { hour: "3" },
            { hour: "4" },
            { hour: "5" },
            { hour: "6" },
            { hour: "7" },
            { hour: "8" },
            { hour: "9" },
            { hour: "10" },
            { hour: "11" },
            { hour: "12" },
            { hour: "13" },
            { hour: "14" },
            { hour: "15" },
            { hour: "16" },
            { hour: "17" },
            { hour: "18" },
            { hour: "19" },
            { hour: "20" },
            { hour: "21" },
            { hour: "22" },
            { hour: "23" }
        ]);

        chartRef.current = chart;
        chart.appear(1000, 100);

        return () => {
            root.dispose();
        }
    }, [data]);

    return (
        <div>
            {title && <p className="text-larger font-weight-bold text-center"> {title} </p>}
            <div ref={containerRef} style={{ minHeight: 250 }}></div>
        </div>
    )
};

export default HeatMapAM5;
