import Breakpoint from "../../breakpoint";
import { ButtonGroup, Dropdown } from "react-bootstrap";

export const ChartFilter = ({
  activeTab,
  isOnline,
  onlinePresets,
  offlinePresets,
  handlePresetChange,
  extraActions = []
}) => {
  return (
    <>
      <Breakpoint down="xl">
        <Dropdown as={ButtonGroup} id="chart-presets-dropdown" title="Dropdown button">
          <Dropdown.Toggle split variant="outline-light" id="chart-presets-dropdown" className="text-white">
            {activeTab?.title}
          </Dropdown.Toggle>
          <Dropdown.Menu variant="dark">
            {(isOnline ? onlinePresets : offlinePresets).map((presetData, index) => (
              <Dropdown.Item active={activeTab?.id === presetData.id} key={index} eventKey={index} onClick={() => {
                if (activeTab?.id !== presetData.id) {
                  handlePresetChange(presetData);
                }}
              }>
                {presetData.title}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Breakpoint>

      <div className={"d-flex align-items-baseline gap-5 overflow-auto py-1"}>
        <form className={"d-flex gap-2 w-100"}>
          <Breakpoint up="xxl">
            <ButtonGroup aria-label="Window size" className="gap-5" style={{ width: "100%", paddingBottom: 8 }}>
              {(isOnline ? onlinePresets : offlinePresets).map((presetData, index) => (
                <button
                  key={index}
                  type="button"
                  className={`button text text-small ${activeTab?.id === presetData.id ? 'active' : ''}`}
                  onClick={() => {
                    if (activeTab?.id !== presetData.id) {
                      handlePresetChange(presetData);
                    }
                  }}>
                  <span style={{ whiteSpace: "nowrap" }}> {presetData.title} </span>
                </button>
              ))}
            </ButtonGroup>
          </Breakpoint>
        </form>

        {extraActions.length > 0 && (
          <div className="text-right mt-2">
            {extraActions.map((actionComponent, index) => (actionComponent(`CHART-FILTER-ACTION-${index}`)))}
          </div>
        )}
      </div>
    </>
  )
}