import { connect } from "react-redux";
import ProjectsList from "./projectsList";
import { setDashboardFilter } from "../../store/slices/dashboard";
import { getProjects, createProject, deleteProject } from "../../store/slices/projects";

function mapStateToProps(state) {
  const {
    list: projects,
    shouldUpdate,
    loading: isLoading,
  } = state.entities?.projects;
  return {
    projects,
    shouldUpdate,
    isLoading,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    getProjects: () => dispatch(getProjects()),
    deleteProject: (projectId) => dispatch(deleteProject(projectId)),
    createProject: (project) => dispatch(createProject(project)),
    setDashboardFilter: (data) => dispatch(setDashboardFilter(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsList);
