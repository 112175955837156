import EditTask from "./editTask";
import { connect } from "react-redux";
import { deleteTask, editTask } from "../../store/slices/tasks";

function mapDispatchToProps(dispatch) {
  return {
    editTask: (data) => dispatch(editTask(data)),
    deleteTask: (taskId) => dispatch(deleteTask(taskId)),
  };
}

export default connect(null, mapDispatchToProps)(EditTask);
