import { Link } from "react-router-dom";

const ProjectDetailsHeader = ({ project, href }) => {
  return (
    <>
      {project && (
        <div className="items-container-heading" key={project.objectId}>
          <div className="d-flex align-items-baseline text-white text-large gap-2">
            {href && <Link to={href} className="text-link text-heading text-large"> Projects </Link>}
            <span className="text-medium"> / </span>
            <span className="text-link text-large"> {project.name} </span>
          </div>

          <p className="text-paragraph text-initial"> {project.description || "No description provided."} </p>
        </div>
      )}
    </>
  );
};

export { ProjectDetailsHeader };
export default ProjectDetailsHeader;