import {Row} from 'react-bootstrap'
import DivergentStackedChartAM5 from '../../charts/divergentStackedChartAM5'

const DivergentChart = (
  {
    metric = "",
    className = "",
    title = "",
    data = [],
  }) => {
  if (!data || data.length === 0) {
    return null
  }

  const chartData = []

  for (let i = 0; i < data.length; i++) {
    const task = data[i]
    chartData.push({
      category: task.title,
      poor: -1 * (task[metric]?.poor || 0),
      baseline: task[metric]?.baseline || 0,
      sufficient: task[metric]?.sufficient || 0
    });
  }

  return <Row
    xs={1} xxl={1} className={`${className} redesign-card`}
    style={{margin: "1rem 1rem 0rem", position: 'relative'}}
  >
    <DivergentStackedChartAM5
      title={title}
      categoryField={"category"}
      data={chartData}
    />
  </Row>;
}

export default DivergentChart;
