import { toast } from "react-toastify";
import * as actions from "../api";

// eslint-disable-next-line consistent-return
const handleError = () => (next) => (action) => {
  if (action.type === actions.apiCallFailed.type) {
    const { message, label } = action.payload;
    const errorMessage = message + (label ? ` (in ${label})` : "");
    
    toast.error(errorMessage, {
      position: toast.POSITION.BOTTOM_LEFT,
      theme: "colored",
    });

  } else return next(action);
};

export default handleError;
