import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { login } from "../store/slices/auth";
import { withTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

const LoginPage = ({ t, loginUser, location, currentUser, ...props }) => {
  const history = useHistory();
  const authState = useSelector((state) => state.entities.auth);

  useEffect(() => {
    if (!authState.isLoading && authState.isLoggedIn && authState.user) {
      history.push("/");
    }
  }, [authState, history]);

  if (!!currentUser) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  return (
    <Container fluid className="h-100">
      <Row className="h-100 align-content-center px-5" xs={1} sm={1} md={2} lg={2}>
        <Col className="flex align-items-center justify-content-center">
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={(values) => {
              loginUser(
                values.email,
                values.password,
                location.state ? location.state.from.pathname : "/",
                history
              );
            }}
          >
            {() => (
              <Form className="form compact flex flex-direction-column">
                <Field name="email">
                  {({ field }) => (
                    <div className="labeled-input fluid">
                      <input className="input" type="email" placeholder=" " required {...field} />
                      <label htmlFor="email"> Email </label>
                    </div>
                  )}
                </Field>

                <Field
                  name="password"
                  secureTextEntry={true}
                >
                  {({ field }) => (
                    <div className="labeled-input fluid">
                      <input className="input" type="password" placeholder=" " required {...field} />
                      <label htmlFor="password"> Password </label>
                    </div>
                  )}
                </Field>

                <Link to="/account/password/reset" className="link align-self-end"> Forgot password? </Link>

                <div className="form-check">
                  <input className="form-check-input" type="checkbox" value="" id="remember-me-check" />
                  <label className="form-check-label text-white" htmlFor="remember-me-check"> Remember me </label>
                </div>

                <button className="button wide align-self-end" type="submit" disabled={authState.isLoading}>
                  {authState.isLoading ? "Signing in..." : "Sign In"}
                </button>

                <p className="text-white align-self-end">
                  Don't have an account? <Link className="link" to="/account/register"> Sign Up </Link>
                </p>
              </Form>
            )}
          </Formik>
        </Col>

        <Col className="flex align-items-center justify-content-center" xs={{ order: "first" }} sm={{ order: "first" }} md={{ order: "last" }} lg={{ order: "last" }}>
          <img src="/images/register-illustration.svg" alt="login" style={{ width: "100%", height: "auto", minWidth: 200 }} />
        </Col>
      </Row>
    </Container>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    loginUser: (email, password, loginRedirectPath, history) => {
      dispatch(login({ email, password, loginRedirectPath, history }));
    },
  };
}

export default connect(null, mapDispatchToProps)(withTranslation()(LoginPage));
