import * as Yup from 'yup';

export const SignUpSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
    password: Yup.string().required("Required"),
    passwordConfirmation: Yup.string().required("Required").test(
        "match",
        "Passwords do not match",
        (value, context) => value && context.parent.password && value === context.parent.password
    ),
    fullName: Yup.string().required("Required").max(100, "Too long - should not exceed 100 characters."),
    company: Yup.string().optional()
});