import { connect } from "react-redux";
import EditSubject from "./editSubject";
import { deleteSubject, editSubject } from "../../store/slices/subjects";

function mapDispatchToProps(dispatch) {
  return {
    editSubject: (data) => dispatch(editSubject(data)),
    deleteSubject: (subjectId) => dispatch(deleteSubject(subjectId)),
  };
}

export default connect(null, mapDispatchToProps)(EditSubject);
