import { join, startsWith, trim } from "lodash";

function addBaseUrl(config) {
  const baseUrl = process.env.REACT_APP_SERVER_URL;
  if (startsWith(config.url, "http://") || startsWith(config.url, "https://")) {
    return config;
  }
  return {
    ...config,
    url: join([trim(baseUrl, "/"), trim(config.url, "/")], "/"),
  };
}

export default addBaseUrl;
