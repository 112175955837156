import { useState, useRef } from "react";
import { Button, Tooltip, Overlay } from "react-bootstrap";
import { FaClipboard, FaClipboardCheck } from "react-icons/fa";

export const ClipboardButton = ({ acquireContent }) => {
  const target = useRef(null);
  const tooltipDisplayTime = 1500;
  const [copied, setCopied] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const copyToClipboard = () => {
    setCopied(true);
    clearTimeout(timeoutId);
    setTimeoutId(setTimeout(() => setCopied(false), tooltipDisplayTime));
    navigator.clipboard.writeText(acquireContent());
  };

  return (
    <>
      <Button ref={target} onClick={() => copyToClipboard()} className="d-flex align-items-center input-group-button" title="Copy to clipboard.">
        {copied ? <FaClipboardCheck /> : <FaClipboard />}
      </Button>
      <Overlay target={target.current} show={copied} placement="top">
        {(props) => (
          <Tooltip id="clipboard-button-overlay" {...props}>
            Copied to clipboard.
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};