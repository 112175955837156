import "../App.scss";
import React from "react";
import Logout from "./logout";
import LoginPage from "./login";
import NotFound from "./notFound";
import RegisterPage from "./register";
import Header from "../components/header";
import { useSelector } from "react-redux";
import DashboardPage from "./dashboardPage";
import ForgotPassword from "./forgotPassword";
import Sidebar from "../components/sidebar/sidebar";
import ForgotPasswordForm from "./forgotPasswordForm";
import { isTokenValid } from "../services/authService";
import { Route, Switch, Redirect } from "react-router-dom";
import ProtectedRoute from "../components/common/protectedRoute";

const Main = () => {
  const { isLoggedIn } = useSelector((state) => state.entities.auth);

  return (
    <div className={`layout ${isLoggedIn ? "adaptive" : ""}`}>
      <Sidebar />
      <Header />

      <div className="Content">
        <Switch>
          <Route
            path="/account/login"
            render={(props) => <LoginPage {...props} currentUser={isTokenValid()} />}
          />
          <Route
            path="/account/register"
            render={(props) => (<RegisterPage {...props} currentUser={isTokenValid()} />)}
          />
          <ProtectedRoute 
            path="/account/logout"
            component={Logout}
          />
          <Route
            path="/account/password/reset/:token"
            render={(props) => (<ForgotPasswordForm {...props} currentUser={isTokenValid()} />)}
          />
          <Route
            path="/account/password/reset"
            render={(props) => (<ForgotPassword {...props} currentUser={isTokenValid()} />)}
          />
          <ProtectedRoute
            path="/dashboard"
            component={DashboardPage}
          />
          <ProtectedRoute path="/" exact>
            <Redirect to="/dashboard" />
          </ProtectedRoute>

          <Route path="/not-found" component={NotFound} />
          <Redirect to="/not-found"></Redirect>
        </Switch>
      </div>
    </div>
  );
};

export default Main;
