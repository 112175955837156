import DivergentChart from './divergentChart'

const DivergentCharts = (
  {
    className = "",
    printLoading = () => {},
    data = {},
  }) => {

  return <>
    {printLoading()}
    <DivergentChart
      className={className}
      title="Tasks Attention Summary"
      data={data.analytics.tasks}
      metric={"attention_grade_summary"}
    />
    <DivergentChart
      className={className}
      title="Tasks Relaxation Summary"
      data={data.analytics.tasks}
      metric={"relaxation_grade_summary"}
    />
    <DivergentChart
      className={className}
      title="Tasks Stress Summary"
      data={data.analytics.tasks}
      metric={"stress_grade_summary"}
    />
    <DivergentChart
      className={className}
      title="Tasks Vigilance Summary"
      data={data.analytics.tasks}
      metric={"vigilance_grade_summary"}
    />
    <DivergentChart
      className={className}
      title="Tasks Mental workload Summary"
      data={data.analytics.tasks}
      metric={"mental_workload_grade_summary"}
    />
  </>;
}

export default DivergentCharts;
