import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import { TASK_URL, ALERTS_URL as url } from "../constants";
import { toast } from "react-toastify";

const slice = createSlice({
  name: "alerts",
  initialState: {
    alertsList: {},
    feedbacksList: {}
  },
  reducers: {
    alertSentRequested: (alerts) => {},

    alertSent: (store, action) => {
      store.alertsList[action.payload.taskId] = [...(store.alertsList[action.payload.taskId] || []), action.payload.result];
      
      toast.info(`Alert sent (${action.payload.title})`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    },

    alertSentFailed: (alerts) => {},

    alertResponseAppended: (store, action) => {
      const allAlerts = store.alertsList[action.payload.taskId] || [];
      const alertWithResponse = action.payload.alertResponse;
      const alert = allAlerts.find((item) => item.objectId === alertWithResponse.objectId);
      
      if (!alert)
        return;
      
      const remainingAlerts = allAlerts.filter((item) => item.objectId !== alertWithResponse.objectId); 
      store.alertsList[action.payload.taskId] = [...remainingAlerts, alertWithResponse];
    },

    feedbackAppended: (store, action) => {
      store.feedbacksList[action.payload.taskId] = [...(store.feedbacksList[action.payload.taskId] || []), action.payload.feedback];
    },

    alertsFeedbacksReceived: (store, action) => {
      const { taskId, alerts, feedbacks } = action.payload.result;
      store.alertsList[taskId] = alerts;
      store.feedbacksList[taskId] = feedbacks;
    },

    alertsFeedbacksFailed: (store, action) => {
      console.log("Failed:", action.paylaod);
    }
  },
});

const { 
  alertSent,
  alertSentRequested,
  alertSentFailed,

  alertResponseAppended,
  feedbackAppended,

  alertsFeedbacksReceived,
  alertsFeedbacksFailed,
} = slice.actions;

export default slice.reducer;

export const sendAlerts = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url,
      method: "POST",
      onStart: alertSentRequested.type,
      onSuccess: alertSent.type,
      onError: alertSentFailed.type,
      data,
      onSuccessPayload: data,
    })
  );
};

export const appendAlertResponse = (alertResponse) => (dispatch) => {
  dispatch({ 
    type: alertResponseAppended,
    payload: { alertResponse, taskId: alertResponse.taskId } 
  });
};

export const appendFeedback = (feedback) => (dispatch) => {
  dispatch({ 
    type: feedbackAppended,
    payload: { feedback, taskId: feedback.taskId },
  });
};

export const getAlertsAndFeedbacks = (taskId) => (dispatch) => {  
  dispatch(
    apiCallBegan({
      url: `${TASK_URL}/${taskId}/alerts-feedbacks`,
      method: "GET",
      onSuccess: alertsFeedbacksReceived,
      onError: alertsFeedbacksFailed,
    })
  );
};
