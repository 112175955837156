export const LOGIN_URL = "authenticate/login";
export const REGISTER_URL = "authenticate/register";
export const FORGOT_PASSWORD_URL = "forgot_password";
export const RESET_PASSWORD_URL = "forgot_password/update/password";

export const PROJECTS_URL = "project";
export const DASHBOARD_URL = "dashboard";
export const SUBJECTS_URL = "subject/list";
export const CREATE_SUBJECT_URL = "subject";
export const TASKS_URL = "task/list";
export const CREATE_TASK_URL = "task";
export const TASK_URL = "task";
export const DOWNLOAD_TASK_DATA_URL = "task/data/download";
export const GET_GCC_URL = "https://insights.innobrain.se/upload";
export const USER_URL = "user";
export const USER_EDIT_URL = "user/update/profile";
export const USER_CHANGE_PASS = "user/update/password";
export const ALERTS_URL = "alert";

export const ASCENDING = "asc";
export const DESCENDING = "desc";

export const TOKEN_KEY = "token";

export const TASK_CHART_RAW_EEG = "raw_eeg";
export const TASK_CHART_PREPROCESSED_EEG = "preprocessed_eeg";
export const TASK_CHART_INSIGHTS = "insights";
export const TASK_CHART_CHARTS = "task_chart";

export const TASK_CHARTS = Object.freeze({
    [TASK_CHART_PREPROCESSED_EEG]: {
        id: TASK_CHART_PREPROCESSED_EEG,
        title: "Preprocessed EEG"
    },
    [TASK_CHART_INSIGHTS]: {
        id: TASK_CHART_INSIGHTS,
        title: "Insights and alerts"
    },
    [TASK_CHART_CHARTS]: {
        id: TASK_CHART_CHARTS,
        title: "Task charts"
    },
});

export const SOCKET_RECONNECTION = true;
export const SOCKET_RECONNECTION_DELAY = 1000;
export const SOCKET_RECONNECTION_DELAY_MAX = 5000;
export const SOCKET_TRANSPORTS = ["websocket"];
export const SOCKET_AUTO_CONNECT = true;
export const SOCKET_PING_INTERVAL = 100;
export const SOCKET_SPEED_TEST_RUNS = 10;
export const SOCKET_SPEED_TEST_DELAY_FROM_INIT = 5000;
