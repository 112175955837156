import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import initialState from "../initialState";
import { PROJECTS_URL as url } from "../constants";
import { toast } from "react-toastify";
import moment from "moment";

const slice = createSlice({
  name: "projects",
  initialState: initialState.projects,
  reducers: {
    projectsRequested: (projects) => {
      projects.loading = true;
    },

    projectsReceived: (projects, action) => {
      let projectsList = action.payload.result.projects;
      projects.additionalData = action.payload.result.additionalData;
      projects.list = projectsList;
      projects.loading = false;
    },

    projectsFailed: (projects) => {
      projects.loading = false;
    },

    projectDetailsRequested: (projects) => {
    },

    projectDetailsReceived: (projects, action) => {
      projects.currentProject = action.payload.result;
    },

    projectDetailsFailed: (projects) => {
      projects.loading = false;
    },

    projectCreateRequested: (projects) => {},

    projectCreated: (projects, action) => {
      projects.list = [...projects.list, action.payload.result];

      toast.info("Project created successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    },

    projectCreateFailed: (projects) => {},

    projectEditRequested: (projects) => {},

    projectEdited: (projects, action) => {
      const updatedProjectIndex = projects.list.findIndex((item) => item.objectId === action.payload.result.objectId);

      if (updatedProjectIndex !== -1) {
        projects.list[updatedProjectIndex] = action.payload.result;
      }

      toast.info("Project updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    },

    projectEditFailed: (projects) => {},

    projectDeleteRequested: (projects) => {},

    projectDeleted: (projects, action) => {
      projects.list = projects.list.filter((project) => project.objectId !== action.payload.result.objectId);

      toast.info("Project deleted successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    },

    projectDeleteFailed: (projects) => {},
  },
});

const {
  projectsRequested,
  projectsReceived,
  projectsFailed,

  projectDetailsRequested,
  projectDetailsReceived,
  projectDetailsFailed,

  projectCreateRequested,
  projectCreated,
  projectCreateFailed,

  projectEditRequested,
  projectEdited,
  projectEditFailed,

  projectDeleteRequested,
  projectDeleted,
  projectDeleteFailed,
} = slice.actions;
export default slice.reducer;

export const getProjects = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url,
      onStart: projectsRequested.type,
      onSuccess: projectsReceived.type,
      onError: projectsFailed.type,
    })
  );
};

export const getProjectDetails = (projectId) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: `${url}/${projectId}`,
      onStart: projectDetailsRequested.type,
      onSuccess: projectDetailsReceived.type,
      onError: projectDetailsFailed.type,
    })
  );
};

export const createProject = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data,
      onStart: projectCreateRequested.type,
      onSuccess: projectCreated.type,
      onError: projectCreateFailed.type,
    })
  );
};

export const editProject = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: `${url}/${data.projectId}`,
      method: "PUT",
      data,
      onStart: projectEditRequested.type,
      onSuccess: projectEdited.type,
      onError: projectEditFailed.type,
    })
  );
};

export const deleteProject = (projectId) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: `${url}/${projectId}`,
      method: "DELETE",
      onStart: projectDeleteRequested.type,
      onSuccess: projectDeleted.type,
      onError: projectDeleteFailed.type,
    })
  );
};
