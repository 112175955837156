import { connect } from "react-redux";
import DashboardCharts from "./dashboardCharts";
import DashboardSummary from "./dashboardSummary";
import { getDashboardData } from "../../store/slices/dashboard";

function mapDispatchToProps(dispatch) {
  return {
    getDashboardData: (data) => dispatch(getDashboardData(data)),
  };
}

const DashboardSummaryWithHOC = connect(null, mapDispatchToProps)(DashboardSummary);

export { 
  DashboardSummaryWithHOC as DashboardSummary,
  DashboardCharts
};