import * as am5 from "@amcharts/amcharts5";
import { useRef, useLayoutEffect } from 'react';
import * as am5XY from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {BLUE_COLOR} from '../dashboard/utils/colors'

const AreaChartAM5 = ({ title, data, categoryField, valueField, unit, tooltipFormat }) => {
    const chartRef = useRef(null);
    const containerRef = useRef(null);

    useLayoutEffect(() => {
        const root = am5.Root.new(containerRef.current);
        const darkTheme = am5themes_Dark.new(root);
        const responsiveTheme = am5themes_Responsive.new(root);
        const animatedTheme = am5themes_Animated.new(root);

        root.setThemes([animatedTheme, darkTheme, responsiveTheme]);
        root._logo.dispose();

        const chart = root.container.children.push(
            am5XY.XYChart.new(root, {
                wheelX: "panX",
                wheelY: "zoomY",
            })
        );

        root.interfaceColors.set("grid", am5.color(0x00000000));

        const cursor = chart.set("cursor", am5XY.XYCursor.new(root, { behavior: 'zoomX' }));
        cursor.lineY.set("visible", false);

        let xAxis = null;

        switch (unit) {
            default:
                xAxis = chart.xAxes.push(
                    am5XY.DateAxis.new(root, {
                        baseInterval: { timeUnit: unit, count: 1 },
                        renderer: am5XY.AxisRendererX.new(root, {
                            strokeOpacity: 1,
                            strokeWidth: 2,
                            stroke: am5.color("#FFFFFF")
                        }),
                        tooltip: am5.Tooltip.new(root, {}),
                        markUnitChange: true,
                        tooltipDateFormat: tooltipFormat,
                    })
                );

                xAxis.get("periodChangeDateFormats")["hour"] = " ";
        }

        var yAxis = chart.yAxes.push(
            am5XY.ValueAxis.new(root, {
                renderer: am5XY.AxisRendererY.new(root, {
                    strokeOpacity: 1,
                    strokeWidth: 2,
                    stroke: am5.color("#FFFFFF")
                }),
            })
        );

        const series = chart.series.push(
            am5XY.LineSeries.new(root, {
                name: "Series",
                xAxis: xAxis,
                yAxis: yAxis,
                valueYField: valueField,
                valueXField: categoryField,
                stroke: BLUE_COLOR,
                fill: BLUE_COLOR,
                tooltip: am5.Tooltip.new(root, {
                    labelText: "{valueY}"
                }),
            })
        );

      yAxis.get("renderer").labels.template.setup = function(target) {
        target.set("fill", am5.color("#BDBDBD"));
        target.set("fontSize", 12);
      };
      xAxis.get("renderer").labels.template.setup = function(target) {
        target.set("fill", am5.color("#BDBDBD"));
        target.set("fontSize", 12);
      };

        series.fills.template.setAll({ fillOpacity: 1, visible: true });
        series.data.setAll(data);

        chartRef.current = chart;

        series.appear(500);
        chart.appear(500, 100);

        return () => {
            root.dispose();
        }
    }, [data]);

    return (
        <div>
          {title && <p className="text-larger font-weight-bold text-center"> {title} </p>}
          <div ref={containerRef} style={{ minHeight: 250 }}></div>
        </div>
    )
};

export default AreaChartAM5;
