import { connect } from "react-redux";
import SubjectsList from "./subjectsList";
import { getProjectDetails } from "../../store/slices/projects";
import { setDashboardFilter } from "../../store/slices/dashboard";
import { createSubject, getSubjects, deleteSubject } from "../../store/slices/subjects";

function mapStateToProps(state) {
  const {
    list: subjects,
    shouldUpdate,
    loading: isLoading,
  } = state.entities.subjects;
  return {
    subjects,
    shouldUpdate,
    isLoading,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSubjects: (projectId) => dispatch(getSubjects(projectId)),
    createSubject: (data) => dispatch(createSubject(data)),
    deleteSubject: (subjectId) => dispatch(deleteSubject(subjectId)),
    getProjectDetails: (projectId) => dispatch(getProjectDetails(projectId)),
    setDashboardFilter: (data) => dispatch(setDashboardFilter(data)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SubjectsList);
