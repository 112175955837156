import { Search } from "../search";
import SortBy from "../sortBy";
import EditSubject from "../editSubject";
import { Row, Col } from "react-bootstrap";
import ListItem from "../listItem/listItem";
import { useParams } from "react-router-dom";
import { useSort } from "../../hooks/useSort";
import { SUBJECT } from "../../utils/constants";
import DeleteItem from "../listItem/deleteItem";
import { useSearch } from "../../hooks/useSearch";
import { capitalize } from "../../utils/stringUtils";
import { useEffect, useState, Children } from "react";
import LoadingComponent from "./../loadingComponent/index";

const SubjectsList = ({
  isLoading,
  subjects,
  getSubjects,
  deleteSubject,
  shouldUpdate,
  isOptionsVisible,
  setDashboardFilter,
  indexedFields,
}) => {
  const { projectId } = useParams();
  const [items, setItems] = useState(subjects);
  const { sortBy, sortData } = useSort(indexedFields);
  const { search, searchValue } = useSearch(indexedFields);

  useEffect(() => {
    getSubjects(projectId);
    setDashboardFilter({ selectedProjectId: projectId, selectedSubjectId: null, selectedTaskId: null });
  }, [getSubjects, setDashboardFilter, projectId]);

  useEffect(() => {
    const items = subjects.map((subject) => ({ ...subject }));
    setItems(sortBy(search(items, searchValue), sortData));
  }, [subjects, search, searchValue, sortBy, sortData]);

  const getSubjectDescription = (subject) => {
    let gender = capitalize(subject.gender || "")
    let age = subject.age || "";
    let handedness = subject.handedness || "";
    age = age ? `${age} years old` : "";
    handedness = handedness ? `${capitalize(handedness)} handed` : "";
    return [gender, age, handedness].join(", ");
  }

  return (
    <div className="text-white d-flex flex-column gap-3">
      {isLoading && <LoadingComponent />}

      {!isLoading && isOptionsVisible &&
        <Row className="filters" style={{ rowGap: "16px" }}>
          <Col sm={4}>
            <SortBy
              keys={indexedFields}
              sortData={sortData}
              onSort={(sortData) => setItems(sortBy(items, sortData))}
            />
          </Col>

          <Col sm={{ span: 6, offset: 2 }}>
            <Search
              searchValue={searchValue}
              onSearch={(value) => setItems(search(sortBy(subjects, sortData), value))}
            />
          </Col>
        </Row>
      }

      {!isLoading && (
        <>
          {items.map((subject) => (
            <ListItem
              item={subject}
              title={subject.name}
              description={getSubjectDescription(subject)}
              source={SUBJECT}
              key={subject.objectId}
              actionButtons={Children.toArray([
                <EditSubject item={subject} />,
                <DeleteItem
                  id={`delete-${SUBJECT}-${subject.objectId}`}
                  handleDeleteItem={() => deleteSubject(subject.objectId)}
                  text="removeSubjectConfirmText"
                />
              ])}
            />
          ))}
        </>
      )}

      {(!isLoading && !searchValue && (!subjects || subjects.length === 0)) && <p className="items-container-empty"> No subjects. Click "+" to create a subject. </p> }
      {searchValue && !items.length && <p className="items-container-empty"> No subjects found. </p>}
    </div>
  );
};

export default SubjectsList;
