import { useState, useEffect } from "react";

export const useBreakpoint = () => {
  const [breakpoint, setBreakpoint] = useState(null);
  const [width, setWidth] = useState(null);

  const getBreakpoint = (width) => {
    if (width < 576) {
      return 0;
    } else if (width < 768) {
      return 576;
    } else if (width < 992) {
      return 768;
    } else if (width < 1200) {
      return 992;
    } else if (width < 1400) {
      return 1200;
    } else {
      return 1400;
    }
  }

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      const currentBreakpoint = getBreakpoint(currentWidth);

      if (currentBreakpoint !== breakpoint) {
        setBreakpoint(currentBreakpoint);
      }

      if (currentWidth !== width) {
        setWidth(currentWidth);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [breakpoint, width]);

  return breakpoint;
}