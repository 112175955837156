import { useEffect } from "react"
import { useSelector, connect } from "react-redux";
import { getUserId } from "../../services/authService";
import { requestSpeedTest } from "../../store/slices/socket";
import { SOCKET_SPEED_TEST_DELAY_FROM_INIT } from "../../store/constants";

const Initializer = ({ requestSpeedTest }) => {
  const { connection, isConnected, isIntroduced, isSpeedTestDone } = useSelector((state) => state.entities.socket);

  useEffect(() => {
    if (isConnected && isIntroduced && !isSpeedTestDone) {
      setTimeout(() => requestSpeedTest(getUserId()), SOCKET_SPEED_TEST_DELAY_FROM_INIT);
    }
  }, [isConnected, isIntroduced, isSpeedTestDone, connection, requestSpeedTest]);

  return null;
}

function mapDispatchToProps(dispatch) {
  return {
    requestSpeedTest: (id) => dispatch(requestSpeedTest(id))
  };
}

export default connect(null, mapDispatchToProps)(Initializer);