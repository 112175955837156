import { BREAKPOINTS } from "../../utils/constants";
import { useBreakpoint } from "../../hooks/useBreakpoint";

/**
 * @param {({
 *  children: React.ReactNode,
 *  up?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl"",
 *  down?: "xs" | "sm" | "md" | "lg" | "xl" | "xxl"
 * }) => void} props
 */
const Breakpoint = ({ children, up, down }) => {
    const breakpoint = useBreakpoint();

    if (up && breakpoint >= BREAKPOINTS[up])
        return children;
    
    if (down && breakpoint <= BREAKPOINTS[down])
        return children;
    
    return null;
}

export default Breakpoint;