import { apiCallBegan } from "../api";
import initialState from "../initialState";
import { createSlice } from "@reduxjs/toolkit";
import authService from "../../services/authService";
import {
  LOGIN_URL,
  REGISTER_URL,
  FORGOT_PASSWORD_URL,
  RESET_PASSWORD_URL,
} from "../constants";
import { toast } from "react-toastify";

const slice = createSlice({
  name: "auth",
  initialState: initialState.auth,
  reducers: {
    userAuthStarted: (auth) => {
      auth.isLoading = true;
    },
    userAuthSucceeded: (auth, action) => {
      auth.isLoading = false;
      auth.isLoggedIn = true;
      auth.user = action.payload.result.user;
      localStorage.setItem("token", action.payload.result.token);
    },
    userAuthFailed: (auth) => {
      auth.isLoading = false;
      auth.isLoggedIn = false;
      auth.user = null;
    },

    userSessionValidated: (auth, action) => {
      auth.isLoading = false;
      auth.isLoggedIn = true;
      auth.user = action.payload.result.user;
    },
    userSessionInvalidated: (auth) => {
      auth.isLoading = false;
      auth.isLoggedIn = false;
      auth.user = null;
      localStorage.removeItem("token");
    },

    userRegisterStarted: (auth) => {
      auth.isLoading = true;
      auth.isRegistrationDone = false;
    },
    userRegisterSucceeded: (auth, action) => {
      auth.isLoading = false;
      auth.isRegistrationDone = true;
    },
    userRegisterFailed: (auth, action) => {
      auth.isLoading = false;
      auth.errors = action.errors;
      auth.isRegistrationDone = false;
    },
    userRegisterResetState: (auth) => {
      auth.errors = {};
      auth.isLoading = false;
      auth.isRegistrationDone = false;
    },

    forgotPasswordStarted: (auth) => {
      auth.isLoading = true;
    },
    forgotPasswordFailed: (auth, action) => {
      auth.errors = action.errors;
      auth.isLoading = false;
    },
    forgotPasswordDone: (auth) => {
      auth.isLoading = false;
      auth.isForgotPasswordDone = true;
    },
    forgotPasswordResetState: (auth) => {
      auth.errors = {};
      auth.isLoading = false;
      auth.isForgotPasswordDone = false;
    },

    resetPasswordDone: (auth, action) => {
      auth.isPasswordResetDone = true;

      toast.info("Your password has been reset.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    },
  },
});

const {
  userSessionValidated,
  userSessionInvalidated,
  
  userAuthStarted,
  userAuthSucceeded,
  userAuthFailed,

  userRegisterStarted,
  userRegisterSucceeded,
  userRegisterFailed,
  userRegisterResetState,
  
  forgotPasswordStarted,
  forgotPasswordFailed,
  forgotPasswordDone,
  forgotPasswordResetState,
  
  resetPasswordDone,
} = slice.actions;
export default slice.reducer;

export const login = (data) => (dispatch) => {
  const url = LOGIN_URL;

  dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data: { email: data.email, password: data.password },
      onStart: userAuthStarted.type,
      onSuccess: userAuthSucceeded.type,
      onSuccessPayload: { path: data.loginRedirectPath },
      onError: userAuthFailed.type,
    })
  );
};

export const register = (data) => (dispatch) => {
  const url = REGISTER_URL;

  dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data,
      onStart: userRegisterStarted.type,
      onSuccess: userRegisterSucceeded.type,
      onError: userRegisterFailed.type,
    })
  );
};

export const sendForgotEmail = (data) => (dispatch) => {
  const url = FORGOT_PASSWORD_URL;
  dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data,
      onStart: forgotPasswordStarted.type,
      onError: forgotPasswordFailed.type,
      onSuccess: forgotPasswordDone.type,
    })
  );
};

export const resetPassword = (data) => (dispatch) => {
  const url = RESET_PASSWORD_URL;

  dispatch(
    apiCallBegan({
      url,
      method: "POST",
      data,
      onSuccess: resetPasswordDone.type,
    })
  );
};

export const validateUserSession = () => (dispatch) => {
  const token = localStorage.getItem("token");
  const user = authService.getCurrentUser();

  if (authService.isTokenValid(token) && user) {
    dispatch({
      type: userSessionValidated.type,
      payload: { result: { user } }
    });

    return;
  }
  
  dispatch({ type: userSessionInvalidated.type });
};

export const resetStateForgotPassword = () => (dispatch) => {
  dispatch({ type: forgotPasswordResetState.type });
};

export const resetStateUserRegister = () => (dispatch) => {
  dispatch({ type: userRegisterResetState.type });
}