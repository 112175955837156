import * as am5 from "@amcharts/amcharts5";
import { useRef, useLayoutEffect } from 'react';
import * as am5XY from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const SpectrumChartAM5 = ({ title, data, categoryField, legends }) => {
    const chartRef = useRef(null);
    const containerRef = useRef(null);

    useLayoutEffect(() => {
        const root = am5.Root.new(containerRef.current);
        const darkTheme = am5themes_Dark.new(root);
        const responsiveTheme = am5themes_Responsive.new(root);
        const animatedTheme = am5themes_Animated.new(root);

        root.setThemes([animatedTheme, darkTheme, responsiveTheme]);
        root._logo.dispose();

        const chart = root.container.children.push(am5XY.XYChart.new(root, {
            panX: false,
            panY: false,
            layout: root.verticalLayout
        }));

        const xRenderer = am5XY.AxisRendererX.new(root, {});
        xRenderer.grid.template.setAll({ location: 0.5 });
        responsiveTheme.addRule({
            name: "AxisRendererX",
            relevant: (width, height) => width < 1350,
            applying: () => xRenderer.labels.template.setAll({ rotation: -45, location: 0.25 }),
            removing: () => xRenderer.labels.template.setAll({ rotation: 0, location: 0.5 })
        });

        const yAxis = chart.yAxes.push(am5XY.CategoryAxis.new(root, {
            categoryField: categoryField,
            renderer: am5XY.AxisRendererY.new(root, {})
        }));
        yAxis.data.setAll([{ category: "" }]);

        const xAxis = chart.xAxes.push(am5XY.DateAxis.new(root, {
            baseInterval: { timeUnit: "second", count: 1 },
            renderer: xRenderer
        }));

        xAxis.get("renderer").labels.template.set("forceHidden", true);

        yAxis.get("renderer").labels.template.setup = function(target) {
            target.set("fill", am5.color("#BDBDBD"));
            target.set("fontSize", 12);
        };
        xAxis.get("renderer").labels.template.setup = function(target) {
            target.set("fill", am5.color("#BDBDBD"));
            target.set("fontSize", 12);
        };

        const series = chart.series.push(am5XY.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: 'to',
            openValueXField: 'from',
            categoryYField: categoryField
        }));

        series.columns.template.setAll({
            strokeWidth: 0,
            strokeOpacity: 0,
            height: am5.percent(100),
            templateField: "columnSettings"
        });

        series.data.setAll(data);

        for(let i = 0; i < data.length; i++) {
            const rangeDataItem = xAxis.makeDataItem({
                value: data[i].from
            });

            xAxis.createAxisRange(rangeDataItem);

            rangeDataItem.get("grid").set("forceHidden", true);

            rangeDataItem.get("tick").setAll({
                visible: true,
                length: 18,
                strokeOpacity: 0.2
            });

            rangeDataItem.get("label").setAll({
                fill: am5.color("#000"),
                centerX: am5.p0,
                forceHidden: false,
                fontSize: 12,
                text: "",
                // text: root.dateFormatter.format(new Date(data[i].from), "HH:mm")
            });
        }

        const legend = chart.children.push(am5.Legend.new(root, {
            nameField: "name",
            fillField: "color",
            strokeField: "color",
            centerX: am5.percent(50),
            x: am5.percent(50),
            clickTarget: "none",
            marginTop: 50,
        }));


        legend.data.setAll(legends);

        chartRef.current = chart;

        chart.appear(1000, 100);

        return () => {
            root.dispose();
        }
    }, [data]);

    return (
        <div>
            {title && <p className="text-larger font-weight-bold text-center"> {title} </p>}
            <div ref={containerRef} style={{ minHeight: 150 }}></div>
        </div>
    )
};

export default SpectrumChartAM5;
