import { Formik, Form, Field } from "formik";
import React, { useState, useRef } from "react";
import { CREATE, EDIT } from "../../utils/constants";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";

const CreateTaskModal = ({
  show,
  handleClose,
  handleSubmit,
  handleDelete,
  task,
  extraIds,
  mode = CREATE,
}) => {
  const handleFormSubmit = (values) => {
    handleSubmit({
      ...values,
      subjectId: extraIds?.subjectId,
      projectId: extraIds?.projectId
    });
  };

  const initialValues = {
    title: task ? task.title : "",
    device: task ? task.device : "",
    timeSeriesData: null,
    preProcessedData: null,
    description: task ? task.description : "",
    sampleRate: task ? task.sampleRate : null,
  };

  return (
    <Modal
      centered
      size="lg"
      show={show}
      onHide={() => handleClose()}
      className="text-center"
    >
      <Modal.Header className="p-0" closeButton closeVariant="white">
        <Modal.Title className="text-white text-large">
          {mode === CREATE && "Create task"}
          {mode === EDIT && "Edit task"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0 mt-4">
        <Formik
          initialValues={initialValues}
          onSubmit={(values) => {
            handleFormSubmit({
              ...values,
              subjectId: extraIds?.subjectId,
              projectId: extraIds?.projectId,
            });
          }}
        >
          {({ setFieldValue }) => (
            <Form className="text-white text-left form flex flex-direction-column">
              <div className="form-group">
                <Field name="title">
                  {({ field }) => (
                    <div className="labeled-input fluid">
                      <input className="input" type="text" placeholder=" " required {...field} />
                      <label htmlFor="title"> Title </label>
                    </div>
                  )}
                </Field>
              </div>

              <div className="form-group">
                <Field name="sampleRate">
                  {({ field }) => (
                    <div className="labeled-input fluid">
                      <input className="input" type="number" placeholder=" " required {...field} />
                      <label htmlFor="sampleRate"> Sample rate </label>
                    </div>
                  )}
                </Field>
              </div>

              <div className="form-group">
                <Field name="device">
                  {({ field }) => (
                    <div className="labeled-input fluid">
                      <input className="input" type="text" placeholder=" " required {...field} />
                      <label htmlFor="device"> Device </label>
                    </div>
                  )}
                </Field>
              </div>

              <div className="form-group">
                <label htmlFor="description"> Description </label>
                <Field
                  rows="7"
                  as="textarea"
                  name="description"
                  className="form-control"
                  placeholder="Caffeine usage, sleep amount, noisy channels…"
                />
              </div>

              <Container fluid className="p-0 mt-3">
                <Row sm={{ cols: 2 }}>
                  <Col className="text-left">
                    {mode === EDIT && (
                      <Button
                        onClick={handleDelete}
                        className="button link"
                      >
                        Delete task
                      </Button>
                    )}
                  </Col>
                  <Col className="text-end">
                    <Button
                      type="submit"
                      className="button w-100"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default CreateTaskModal;
