import { Modal, Button } from "react-bootstrap";

const ConfirmRemoveModal = ({ show, handleDelete, handleClose, text }) => {
  return (
    <Modal
      centered
      size="lg"
      show={show}
      onHide={handleClose}
      className="text-center text-white"
    >
      <Modal.Body>
        <h1> Are you sure? </h1>
        {text}
        <br />
        <div className="d-grid gap-2 my-3">
          <Button
            onClick={handleDelete}
            variant="outline-light"
          >
            Delete
          </Button>
          <Button onClick={handleClose} variant="outline-light" className="mt-2">
            Cancel
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ConfirmRemoveModal;
