import { FaSearch } from "react-icons/fa";

const Search = ({ onSearch, searchValue }) => {
  return (
    <div className="d-flex search-input">
      <FaSearch />
      <div className="labeled-input fluid">
        <input
          className="input borderless"
          type="text" 
          placeholder=" "
          value={searchValue}
          onChange={(event) => onSearch && onSearch(event.target.value)}
        />
        <label> Search... </label>
      </div>
    </div>
  )
};

export default Search;