import { apiCallBegan } from "../api";
import initialState from "../initialState";
import { createSlice } from "@reduxjs/toolkit";
import { DASHBOARD_URL as url } from "../constants";

const slice = createSlice({
  name: "dashboard",
  initialState: initialState.dashboard,
  reducers: {
    dashboardFilterSelected: (dashboard, action) => {
      const selectedProjectId = action.payload.selectedProjectId || '';
      const selectedSubjectId = action.payload.selectedSubjectId || '';
      const selectedTaskId = action.payload.selectedTaskId || '';
      dashboard.selectedProjectId = selectedProjectId;
      dashboard.selectedSubjectId = selectedSubjectId;
      dashboard.selectedTaskId = selectedTaskId;
      dashboard.shouldUpdate = true;
    },
    dashboardRequested: (dashboard, action) => {
      dashboard.loadingQueries.push({ type: action.type });
      dashboard.shouldUpdate = false;
    },
    dashboardReceived: (dashboard, action) => {
      dashboard.data = action.payload.result;
      dashboard.loadingQueries.pop();
      dashboard.shouldUpdate = false;
    },
    dashboardFailed: (dashboard) => {
      dashboard.loadingQueries.pop();
      dashboard.shouldUpdate = false;
    }
  }
});

const {
  dashboardRequested,
  dashboardReceived,
  dashboardFailed,
  dashboardFilterSelected
} = slice.actions;
export default slice.reducer;

export const getDashboardData = (data) => (dispatch) => {
  for (const key in data) {
    if (data[key] === null || data[key] === undefined || data[key] === '') {
      delete data[key];
    }
  }

  const params = new URLSearchParams(data).toString();
  const urlWithParams = params ? `${url}?${params}` : url;

  dispatch(
    apiCallBegan({
      url: urlWithParams,
      method: "GET",
      onStart: dashboardRequested.type,
      onSuccess: dashboardReceived.type,
      onError: dashboardFailed.type
    })
  );
};

export const setDashboardFilter = (data) => (dispatch) => {
  dispatch({
    type: dashboardFilterSelected.type,
    payload: data,
  });
};
