import { useState, useCallback } from "react";

export const useSearch = (searchKeys) => {
  const [searchValue, setSearchValue] = useState("");

  const search = useCallback((items, searchValue) => {
    setSearchValue(searchValue);

    return [...items].filter((item) => {
        if (searchValue === "") {
            return true;
        }

        return searchKeys.some((key) => {
            let value = item[key];

            if (typeof value === "number")
                value = value.toString();

            if (typeof value === "string")
                return value.toLowerCase().includes(searchValue.toLowerCase());

            return false;
        });
    });
  }, [searchKeys]);

  return {
    search,
    searchValue,
  };
}