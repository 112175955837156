import React from "react";
import { useSelector, connect } from "react-redux";
import { TASK_CHARTS } from "../../store/constants";
import { selectChart } from "../../store/slices/tasks";
import { Navbar, Nav, Container } from "react-bootstrap";

const TaskChartNavigator = ({ setActiveChart }) => {
  const { activeChart } = useSelector(state => state.entities.tasks);

  return (
    <Navbar variant="dark" className="mt-4">
      <Container fluid className="p-0">
        <Nav className="gap-4">
          {Object.values(TASK_CHARTS).map((chart) => (
            <Nav.Link
              className={`text-medium text-white ${(activeChart.id === chart.id) && "text-strong"}`}
              href={`#${activeChart.id}`}
              active={activeChart.id === chart.id}
              key={chart.id}
              onClick={() => {
                if (activeChart.id === chart.id)
                  return;

                setActiveChart(chart);
              }}>
              {chart.title}
            </Nav.Link>
          ))}
        </Nav>
      </Container>
    </Navbar>
  )
};

function mapDispatchToProps(dispatch) {
  return {
    setActiveChart: (chartType) => {
      dispatch(selectChart(chartType));
    }
  };
}

export default connect(null, mapDispatchToProps)(TaskChartNavigator);