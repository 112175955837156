import { Form } from "formik";
import { useEffect } from "react";
import { Field, Formik } from "formik";
import { connect, useSelector } from "react-redux";
import { resetPassword } from "../store/slices/auth";
import { Container, Row, Col } from "react-bootstrap";
import { Redirect, useParams, Link, useHistory } from "react-router-dom";

const ForgotPasswordForm = ({ resetPassword, currentUser }) => {
  const { token } = useParams();
  const history = useHistory();
  const { isPasswordResetDone } = useSelector((state) => state.entities.auth);

  useEffect(() => {
    if (isPasswordResetDone) {
      history.push("/account/login");
    }
  }, [isPasswordResetDone, history]);

  if (!!currentUser) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  return (
    <Container fluid className="h-100">
      <Row className="h-100 align-content-center px-5" xs={1} sm={1} md={2} lg={2}>
        <Col className="flex align-items-center justify-content-center">
          <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={(values) => {
              resetPassword({ newPassword: values.newPassword, token });
            }}
          >
            {() => (
              <Form className="form compact flex flex-direction-column">
                <Field
                  name="password"
                  secureTextEntry={true}
                >
                  {({ field }) => (
                    <div className="labeled-input fluid">
                      <input className="input" type="password" placeholder=" " required {...field} />
                      <label htmlFor="password"> Password </label>
                    </div>
                  )}
                </Field>

                <Field
                  name="newPassword"
                  secureTextEntry={true}
                >
                  {({ field }) => (
                    <div className="labeled-input fluid">
                      <input className="input" type="password" placeholder=" " required {...field} />
                      <label htmlFor="newPassword"> Confirm password </label>
                    </div>
                  )}
                </Field>

                <button className="button wide align-self-end" type="submit">
                  Done
                </button>

                <p className="text-white align-self-end">
                  <Link className="link" to="/account/login"> Sign in </Link>
                </p>
              </Form>
            )}
          </Formik>
        </Col>

        <Col className="flex align-items-center justify-content-center" xs={{ order: "first" }} sm={{ order: "first" }} md={{ order: "last" }} lg={{ order: "last" }}>
          <img src="/images/register-illustration.svg" alt="login" style={{ width: "100%", height: "auto", minWidth: 200 }} />
        </Col>
      </Row>
    </Container>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    resetPassword: (data) => dispatch(resetPassword(data)),
  };
}

export default connect(null, mapDispatchToProps)(ForgotPasswordForm);
