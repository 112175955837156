import {Row} from 'react-bootstrap'
import SpectrumChartAM5 from '../../charts/spectrumChartAM5'
import {BASELINE_COLOR, POOR_COLOR, SUFFICIENT_COLOR} from './colors'

const SpectrumChart = (
  {
    className = "",
    title = "",
    data = [],
  }) => {
  if (!data || !data.length === 0) {
    return null
  }

  const chartData = []

  const getFill = (value) => {
    if (value === 50) {
      return POOR_COLOR
    } else if (value === 75) {
      return SUFFICIENT_COLOR
    } else {
      return BASELINE_COLOR
    }
  }

  for (let i = 0; i < data.length; i++) {
    const metric = data[i]
    const fromDate = new Date();
    const toDate = new Date();
    const splittedFromDate = metric[0].split(":")
    const splittedToDate = metric[1].split(":")

    fromDate.setHours(parseInt(splittedFromDate[0]))
    fromDate.setMinutes(parseInt(splittedFromDate[1]))
    fromDate.setSeconds(Math.round(parseInt(splittedFromDate[2])))

    toDate.setHours(parseInt(splittedToDate[0]))
    toDate.setMinutes(parseInt(splittedToDate[1]))
    toDate.setSeconds(Math.round(parseInt(splittedToDate[2])))

    console.log('yaser', { color: getFill(metric[2]), title })
    chartData.push({
      category: "",
      from: fromDate.getTime(),
      to: toDate.getTime(),
      columnSettings: {
        fill: getFill(metric[2])
      }
    })
  }

  return <Row
    xs={1} xxl={1} className={`${className} redesign-card`}
    style={{margin: "1rem 1rem 0rem", position: 'relative'}}
  >
    <SpectrumChartAM5
      title={title}
      categoryField={"category"}
      data={chartData}
      legends={[{
        name: "Poor",
        color: POOR_COLOR
      }, {
        name: "Baseline",
        color: BASELINE_COLOR
      }, {
        name: "Sufficient",
        color: SUFFICIENT_COLOR
      }]}
    />
  </Row>;
}

export default SpectrumChart;
