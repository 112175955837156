import * as am5 from "@amcharts/amcharts5";
import { useRef, useLayoutEffect } from 'react';
import * as am5XY from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const GrainyBarChartAM5 = ({ title, data, valueField, categoryField, colors }) => {
    const chartRef = useRef(null);
    const containerRef = useRef(null);

    useLayoutEffect(() => {
        const root = am5.Root.new(containerRef.current);
        const darkTheme = am5themes_Dark.new(root);
        const responsiveTheme = am5themes_Responsive.new(root);
        const animatedTheme = am5themes_Animated.new(root);

        root.setThemes([animatedTheme, darkTheme, responsiveTheme]);
        root._logo.dispose();

        const chart = root.container.children.push(am5XY.XYChart.new(root, {
            panX: true,
            panY: true,
            pinchZoomX: true,
            paddingLeft:0,
            layout: root.verticalLayout
        }));

        chart.set("colors", am5.ColorSet.new(root, {
            colors: colors
        }))

        const xRenderer = am5XY.AxisRendererX.new(root, {
            minGridDistance: 0,
            minorGridEnabled: true
        });

        xRenderer.grid.template.setAll({
            location: 0.5
        })

        responsiveTheme.addRule({
            name: "AxisRendererX",
            relevant: (width, height) => width < 1350,
            applying: () => xRenderer.labels.template.setAll({ rotation: -45, location: 0.25 }),
            removing: () => xRenderer.labels.template.setAll({ rotation: 0, location: 0.5 })
        });

        const xAxis = chart.xAxes.push(am5XY.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: categoryField,
            renderer: xRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));

        const yAxis = chart.yAxes.push(am5XY.ValueAxis.new(root, {
            maxDeviation: 0.3,
            min: 0,
            renderer: am5XY.AxisRendererY.new(root, {
                strokeOpacity: 0.1
            })
        }));

        yAxis.get("renderer").labels.template.setup = function(target) {
            target.set("fill", am5.color("#BDBDBD"));
            target.set("fontSize", 12);
        };
        xAxis.get("renderer").labels.template.setup = function(target) {
            target.set("fill", am5.color("#BDBDBD"));
            target.set("fontSize", 12);
        };

        const series = chart.series.push(am5XY.ColumnSeries.new(root, {
            name: title,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: valueField,
            categoryXField: categoryField,
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueY}"
            }),
        }));

        series.columns.template.setAll({
            tooltipY: 0,
            tooltipText: "{categoryX}: {valueY}",
            shadowOpacity: 0.1,
            shadowOffsetX: 2,
            shadowOffsetY: 2,
            shadowBlur: 1,
            strokeWidth: 0.1,
            stroke: am5.color(0xffffff),
            shadowColor: am5.color(0x000000),
            cornerRadiusTL: 50,
            cornerRadiusTR: 50,
            width: 30,
        });


        series.columns.template.states.create("hover", {
            shadowOpacity: 1,
            shadowBlur: 10,
            cornerRadiusTL: 10,
            cornerRadiusTR: 10
        })

        series.columns.template.adapters.add("fill", function (fill, target) {
            return chart.get("colors").getIndex(series.columns.indexOf(target));
        });

        xAxis.data.setAll(data);
        series.data.setAll(data);

        chartRef.current = chart;
        chart.appear(1000, 100);

        return () => {
            root.dispose();
        }
    }, [data]);

    return (
        <div>
            {title && <p className="text-larger font-weight-bold text-center"> {title} </p>}
            <div ref={containerRef} style={{ minHeight: 250 }}></div>
        </div>
    )
};

export default GrainyBarChartAM5;
