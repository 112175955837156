import * as am5 from "@amcharts/amcharts5";
import { useRef, useLayoutEffect } from 'react';
import * as am5XY from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const colors = [am5.color(0xFF7F2A), am5.color(0xFF7F2A)];

const BarChartAM5 = ({ title, data, categoryField, valueField, showLegend = true }) => {
    const chartRef = useRef(null);
    const containerRef = useRef(null);

    useLayoutEffect(() => {
        const root = am5.Root.new(containerRef.current);
        const darkTheme = am5themes_Dark.new(root);
        const responsiveTheme = am5themes_Responsive.new(root);
        const animatedTheme = am5themes_Animated.new(root);

        root.setThemes([animatedTheme, darkTheme, responsiveTheme]);
        root._logo.dispose();

        const chart = root.container.children.push(
            am5XY.XYChart.new(root, {
                panX: false,
                panY: false,
                wheelX: "panX",
                wheelY: "zoomX",
            })
        );

        root.interfaceColors.set("grid", am5.color(0x00000000));

        const cursor = chart.set("cursor", am5XY.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);

        const xRenderer = am5XY.AxisRendererX.new(root, {
            minGridDistance: 30,
            strokeOpacity: 1,
            strokeWidth: 2,
            stroke: am5.color("#FFFFFF")
        });

        xRenderer.grid.template.setAll({ location: 0.5 });

        responsiveTheme.addRule({
            name: "AxisRendererX",
            relevant: (width, height) => width < 350,
            applying: () => xRenderer.labels.template.setAll({ rotation: -45, location: 0.25 }),
            removing: () => xRenderer.labels.template.setAll({ rotation: 0, location: 0.5 })
        });

        var xAxis = chart.xAxes.push(am5XY.CategoryAxis.new(root, {
            maxDeviation: 0.5,
            renderer: xRenderer,
            categoryField: categoryField,
            tooltip: am5.Tooltip.new(root, {}),
        }));

        var yAxis = chart.yAxes.push(am5XY.ValueAxis.new(root, {
            maxDeviation: 0.3,
            numberFormat: "#'%'",
            renderer: am5XY.AxisRendererY.new(root, {
                strokeOpacity: 1,
                strokeWidth: 2,
                stroke: am5.color("#FFFFFF")
            }),
        }));

        var series = chart.series.push(am5XY.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: valueField,
            sequencedInterpolation: true,
            categoryXField: categoryField,
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueY}%"
            }),
        }));

        series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0 });
        series.columns.template.adapters.add("fill", (_fill, target) => colors[series.columns.indexOf(target) % 2])
        series.columns.template.adapters.add("stroke", (_stroke, target) => colors[series.columns.indexOf(target) % 2]);

        series.data.setAll(data);
        xAxis.data.setAll(data);

        if (showLegend) {
            const legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.p50,
                x: am5.p50,
                y: am5.p100,
                nameField: "categoryX",
                layout: am5.GridLayout.new(root, {
                    maxColumns: 3,
                    fixedWidthGrid: true,
                })
            }));

            legend.data.setAll(series.dataItems.filter((item) => item.dataContext.value !== 0));
        }

        chartRef.current = chart;

        // series.appear(500);
        chart.appear(500, 100);

        return () => {
            root.dispose();
        }
    }, [data]);

    return (
        <div>
            {title && <p className="text-larger font-weight-bold text-center"> {title} </p>}
            <div ref={containerRef} style={{ minHeight: 250 }}></div>
        </div>
    )
};

export default BarChartAM5;
