import * as am5 from "@amcharts/amcharts5";
import { useRef, useLayoutEffect, useState } from "react";
import * as am5Percent from "@amcharts/amcharts5/percent";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import {BLUE_RGB_COLOR, ORANGE_RGB_COLOR} from '../dashboard/utils/colors'

const DonutChartAM5 = ({ title, data, categoryField, valueField, colors, showLegend = true }) => {
    const chartRef = useRef(null);
    const containerRef = useRef(null);
    const [legend, setLegend] = useState(null)

    useLayoutEffect(() => {
        const root = am5.Root.new(containerRef.current);

        root.setThemes([
            am5themes_Dark.new(root),
            am5themes_Animated.new(root),
        ]);

        root._logo.dispose();

        const chart = root.container.children.push(am5Percent.PieChart.new(root, {
            layout: root.verticalLayout,
            innerRadius: am5.percent(50)
        }));

        const series = chart.series.push(am5Percent.PieSeries.new(root, {
            valueField: valueField,
            categoryField: categoryField,
            alignLabels: false
        }));

        series.labels.template.setAll({
            textType: "circular",
            centerX: 0,
            centerY: 0
        });

        series.get("colors").set("colors", colors);

        series.data.setAll(data);

        series.labels.template.setAll({
            centerX: 0,
            centerY: 0,
            visible: false,
        });

        if (showLegend) {
            const legend = chart.children.push(am5.Legend.new(root, {
                centerX: am5.percent(50),
                x: am5.percent(50),
                marginTop: 15,
                marginBottom: 15,
            }));

            legend.data.setAll(series.dataItems);
            legend.hide();
            setLegend(legend);
        }

        chartRef.current = chart;

        series.appear(500);
        chart.appear(500, 100);

        return () => {
            root.dispose();
        }
    }, [data]);

    const sortLegendItems = (leftInstance, rightInstance) => {
        const left = leftInstance._settings.category;
        const right = rightInstance._settings.category;

        if (left < right) {
            return -1;
        }

        if (left > right){
            return 1;
        }

        return 0;
    }

    const getLegendColor = (legendData) => {
        if (legendData.dataContext.name === "Male") {
            return BLUE_RGB_COLOR
        }
        return ORANGE_RGB_COLOR;
    }

    return (
        <div>
            {title && <p className="text-larger font-weight-bold text-center"> {title} </p>}
            <div ref={containerRef} style={{ minHeight: 185 }}></div>

            {legend && showLegend && <div className="flex w-100 gap-2 justify-content-center flex-wrap">
                {legend.data.values.sort(sortLegendItems).map((legendData) => (
                    <div className="legend-item flex align-items-center gap-1" key={`${legendData.uid}-${legendData.dataContext.id}`}>
                        <span className="legend-marker" style={{ backgroundColor: getLegendColor(legendData) }}></span>
                        <span className="legend-title"> {legendData._settings.category} </span>
                    </div>
                ))}
            </div>}
        </div>
    )
};

export default DonutChartAM5;
