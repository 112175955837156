import { humanize } from "../../utils/stringUtils";
import { FaSortAlphaUp, FaSortAlphaDown } from "react-icons/fa";
import { DropdownButton, ButtonGroup, Dropdown, Button } from "react-bootstrap";

const SortBy = ({
  keys,
  onSort,
  sortData,
  className = "",
}) => {
  const getSortTitle = () => {
    const icon = sortData.order === "ASC" ? <FaSortAlphaUp /> : <FaSortAlphaDown />;

    return (
      <>
        {icon} &nbsp;
        {humanize(sortData.key)}
      </>
    )
  };

  return (
    <div className={className}>
      <DropdownButton menuVariant="dark" title={getSortTitle()} className="custom-dropdown">
        {keys.map((key) => (
          <Dropdown.Item
            key={key}
            as={Button}
            className="rounded-0 border-0"
            variant="outline-light"
            active={sortData.key === key}
            onClick={() => onSort({ ...sortData, key })}
          >
            {humanize(key)}
          </Dropdown.Item>
        ))}
        <Dropdown.Divider />
        <ButtonGroup className="w-100">
          {[
            { name: "DESC", icon: <FaSortAlphaDown />, title: "Descending order" },
            { name: "ASC", icon: <FaSortAlphaUp />, title: "Ascending order" },
          ].map(({ name, icon, title }) => (
            <Button
              key={name}
              title={title}
              active={sortData.order === name}
              variant="outline-dark"
              className="rounded-0 border-0 text-white"
              onClick={() => onSort({ ...sortData, order: name })}
            >
              {icon}
            </Button>
          ))}
        </ButtonGroup>
      </DropdownButton>
    </div>
  );
};

export default SortBy;