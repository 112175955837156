import * as am5 from '@amcharts/amcharts5'

export const BLUE_RGB_COLOR = "#0076AD"
export const ORANGE_RGB_COLOR = "#D9A31A"
export const GREEN_RGB_COLOR = "#008A5C"


export const SUFFICIENT_COLOR = am5.color(GREEN_RGB_COLOR)
export const POOR_COLOR = am5.color(ORANGE_RGB_COLOR)
export const BASELINE_COLOR = am5.color(BLUE_RGB_COLOR)
export const BLUE_COLOR = am5.color(BLUE_RGB_COLOR)
export const ORANGE_COLOR = am5.color(ORANGE_RGB_COLOR)
