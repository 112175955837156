import { connect } from "react-redux";
import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "../../services/authService";
import { validateUserSession } from "../../store/slices/auth";

const ProtectedRoute = ({ path, validateUserSession, component: Component, render, ...rest }) => {
  useEffect(() => {
    validateUserSession(); 
  }, [validateUserSession]);

  return (
    <Route
      {...rest}
      path={path}
      render={(props) => {
        if (isLoggedIn()) {
          return Component ? <Component {...props} /> : render(props);
        }

        return (
          <Redirect
            to={{
              pathname: "/account/login",
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

function mapDispatchToProps(dispatch) {
  return {
    validateUserSession: () => dispatch(validateUserSession())
  };
}

export default connect(null, mapDispatchToProps)(ProtectedRoute);
