import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Breakpoint from "../../breakpoint";
import TooltipHandle from "../../tooltipHandle";
import { Button, ButtonGroup } from "react-bootstrap";
import { formatMillisecondsDuration } from "../../../utils/stringUtils";

const handleRender = (node, handleProps, activeTab) => {
  return (
    <TooltipHandle
      value={handleProps.value}
      visible={handleProps.dragging}
      tipFormatter={(page) => {
        if (!activeTab)
          return "";

        const length = activeTab.durationInSeconds * 1000;
        const startTime = formatMillisecondsDuration((page - 1) * length);
        const endTime = formatMillisecondsDuration(page * length);
        return `${startTime} - ${endTime}`;
      }}
    >
      {node}
    </TooltipHandle>
  );
}

export const ChartPaginator = ({
  page,
  activeTab,
  processPageChange,
}) => {
  const turnPage = (event) => {
    const { pageAction } = event.target.dataset;

    switch (pageAction) {
      case "FIRST":
        processPageChange(1);
        break;
      case "PREVIOUS":
        if (page.active === 1) return;
        processPageChange(page.active - 1);
        break;
      case "NEXT":
        if (page.active === page.last) return;
        processPageChange(page.active + 1);
        break;
      case "LAST":
        processPageChange(page.last);
        break;
      default:
        break;
    }
  }

  return (
    <div className={"d-flex align-items-center pt-3 pb-3"}>
      <Breakpoint up="sm">
        <ButtonGroup className="gap-3">
          <button disabled={page.active === 1} className="button text" data-page-action="FIRST" onClick={turnPage}>
            First
          </button>
          <button disabled={page.active === 1} className="button text" data-page-action="PREVIOUS" onClick={turnPage}>
            Previous
          </button>
        </ButtonGroup>

        <div className={"px-3 w-100"}>
          <Slider
            min={1}
            max={page.last}
            step={1}
            railStyle={{ backgroundColor: "#FFFFFF", height: 2 }}
            handleStyle={{ backgroundColor: "#49E5FB", opacity: 1 }}
            trackStyle={{ backgroundColor: "#FFFFFF", height: 2 }}
            value={page.active}
            handleRender={(node, handleProps) => handleRender(node, handleProps, activeTab)}
            onChange={processPageChange}
          />
        </div>

        <ButtonGroup className="gap-3">
          <button disabled={page.active === page.last} className="button text" data-page-action="NEXT" onClick={turnPage}>
            Next
          </button>
          <button disabled={page.active === page.last} className="button text" data-page-action="LAST" onClick={turnPage}>
            Last
          </button>
        </ButtonGroup>
      </Breakpoint>

      <Breakpoint down="xs">
        <ButtonGroup>
          <Button disabled={page.active === 1} variant="outline-light" data-page-action="FIRST" onClick={turnPage}>
            First
          </Button>
          <Button disabled={page.active === 1} variant="outline-light" data-page-action="PREVIOUS" onClick={turnPage}>
            Previous
          </Button>
          <Button disabled={page.active === page.last} variant="outline-light" data-page-action="NEXT" onClick={turnPage}>
            Next
          </Button>
          <Button disabled={page.active === page.last} variant="outline-light" data-page-action="LAST" onClick={turnPage}>
            Last
          </Button>
        </ButtonGroup>
      </Breakpoint>
    </div>
  );
}